import React, { useEffect, useState } from "react";
import Slider from "react-slick";
// Local
import PropertyPriceSliderComp from "./PriceSliderComp";
import { httpRequest } from "../Pages/Server/Axios";
import { GET_LIST_OF_PROPERTIES_FOR_SLIDER } from "../Pages/Server/Apis";
import { Typography } from "@mui/material";
import { AmountSaperator } from "../Utilities";

function PropertyPriceSlider() {
    const [propertyData, setPropertyData] = useState([]);
    const [sLoader, setLoader] = useState(false);
    const [sError, setError] = useState('');
    const [open, setOpen] = React.useState(false);
    const [date, setDate] = React.useState("");

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
      };

      const propertySliderData = [
        {
          id:0,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "3.6%",
          xProgress: true,
        },
        {
          id:1,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "2.6%",
          xProgress: false,
        },
        {
          id:2,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "3.6%",
          xProgress: true,
        },
        {
          id:3,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "1.6%",
          xProgress: false,
        },
        {
          id:4,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "3.6%",
          xProgress: true,
        },
        {
          id:5,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "4.6%",
          xProgress: false,
        },
        {
          id:6,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "2.6%",
          xProgress: true,
        },
        {
          id:7,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "3.6%",
          xProgress: false,
        },
        {
          id:8,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "9.6%",
          xProgress: true,
        },
        {
          id:9,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "3.6%",
          xProgress: false,
        },
        {
          id:10,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "7.6%",
          xProgress: true,
        },
        {
          id:11,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "9.6%",
          xProgress: true,
        },
        {
          id:12,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "3.6%",
          xProgress: false,
        },
        {
          id:13,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "7.6%",
          xProgress: true,
        },
        {
          id:14,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "9.6%",
          xProgress: true,
        },
        {
          id:15,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "3.6%",
          xProgress: false,
        },
        {
          id:16,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "7.6%",
          xProgress: true,
        },
        {
          id:17,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "9.6%",
          xProgress: true,
        },
        {
          id:18,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "3.6%",
          xProgress: false,
        },
        {
          id:19,
          xProjectName: "Sky Residence II",
          xDevelopedBy: "Expo City",
          xRate: "7.6%",
          xProgress: true,
        },
      ]

      useEffect(() => {
        getPropertyListForSlider();
      }, []);

      const getPropertyListForSlider = async() => {
        setLoader(true);
        const API_LIST_OF_PROPERTY_FOR_SLIDER_CONFIG = GET_LIST_OF_PROPERTIES_FOR_SLIDER();
        
        try {
           const response = await httpRequest(API_LIST_OF_PROPERTY_FOR_SLIDER_CONFIG);
            // console.log("**** List of Properties Slider ****** ", response);

           if(response.status == 1){
              setPropertyData(response.data);
              setDate(response.evidence_date);
           }
        } catch (error) {
            setOpen(true);
            setLoader(false);
            setError(error.message);
        }
      }

      return (
        <div className="slider-container">
          {/* <Typography variant="body2" color={'gray'} style={{ margin:'1rem', fontFamily:"sans-serif" }} >
             LAST 24HR UPDATES
          </Typography> */}
          <Slider {...settings}>
            {
              // propertySliderData.map((e) => {
                propertyData.slice(0, 10).map((e, index) => {
                return(
                  <PropertyPriceSliderComp 
                    key={index}
                    xProjectName={e.loc}
                    xDevelopedBy={e.emirate}
                    xRate={e.percentage_change}
                    xProgress={e.percentage_change > 0? true : false}
                    xDate={date}
                    xMedianPrice={AmountSaperator(e.average_sale_price)}
                  />
                )
              })
            }
          </Slider>
        </div>
      );
}

export default PropertyPriceSlider;
