import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Button, Snackbar, Box, LinearProgress, Typography, Modal, Fade, Backdrop, Card, IconButton, Menu, MenuItem  } from '@mui/material';
import { AmountSaperator } from '../Utilities';
import { useNavigate } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
// import localStorage from 'redux-persist/es/storage';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Local 

import { propertyImagesUrl } from '../Pages/Server/baseUrls';
import Placeholder from "../Images/placeholder1.png"
import { httpRequest } from '../Pages/Server/Axios';
import { List_OF_Properties } from '../LocalDB';
import { GET_LIST_OF_CLIENT_PROPERTIES, LIST_PROPERTY_WITH_K_ESTATES } from '../Pages/Server/Apis';
import LoaderComp from './LoaderComp';
import { message } from 'antd';

const ImageGalleryModal = ({ images, open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      // closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 500,
      // }}
      style={{
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        backgroundColor: 'transparent', // Set the background color to transparent
        boxShadow: 'none', // Remove the shadow effect
      }}
      
    >
        {/* <div style={{ width:"50%", height:"50vh" }} >
          {images.map((image, index) => {
            return(
              (
                <img key={index} src={`${propertyImagesUrl}/${image.name}`} alt={`Image ${index}`} style={{ width: '100%', marginBottom: '10px' }} />
              )
            )
          })}
        </div> */}
        <Card style={{ width:'50%', height:"50vh", }} >
          <Slide autoplay={false} >
            {images.map((slideImage, index)=> {
              return(
                <div key={index} style={{  backgroundColor:'black' }} >
                    {/* <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                    </div> */}
                    <img
                        src={`${propertyImagesUrl}/${slideImage.name}`}
                        style={{
                            width: '100%',
                            height: '50vh', // Use 'auto' for height to maintain aspect ratio
                            maxWidth: '100%', // Ensure image doesn't exceed its container's width
                            // maxHeight: '16rem', // Limit the maximum height of the image
                            objectFit: 'cover', // Cover the entire container while maintaining aspect ratio
                            opacity: 0.5,
                        }}
                        alt="Slide Image"
                    />
                </div>
            )
            })} 
          </Slide>
        </Card>
    </Modal>
  );
};

const PropertyTableComp = ({
    xData,
    xOnClick
}) => {
  const navigate = useNavigate();
  const tokenInfo = useSelector((state) => state.login.token);
  const exchangeRate = 3.677;
  const sqftToSqm = (sqft) => {
    return sqft * 0.092903;
  };

  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [propertyData, setPropertyData] = useState([]);
  const [sLoader, setLoader] = useState(false);
  const [sError, setError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currencyMenu, setCurrnecyMenu] = useState(null);
  const [areaSizeMenu, setAreaSizeMenu] = useState(null);
  const [currency, setCurrency] = useState('AED');
  const [unit, setUnit] = useState('sqft');
  const [sListingLoader, setListingLoader] = useState(false);
  const [sListingeError, setListingError] = useState('');
  const [rowData, setRowData] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: 'success',
      content: "Property Successfully Listed With K Estates",
      duration: 5
    });
  };

  useEffect(() => {
    getListOfProperties();
  }, []);

  const handleClick = (event, row) => {
    setRowData(row);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuItem = () => {
    setAnchorEl(null);
  };

  const handleCurrencyMenu = (event) => {
    setCurrnecyMenu(event.currentTarget);
  };

  const hadleCloseCurrencyMenu = () => {
    setCurrnecyMenu(null);
  };

  const handleAreaSizeMenu = (event) => {
    setAreaSizeMenu(event.currentTarget);
  };

  const handleCloseAreaSizeMenu = () => {
    setAreaSizeMenu(null);
  };

  const handleGalleryClick = (images) => {
    setSelectedImages(images);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getListOfProperties = async() => {
    setLoader(true);
    const API_LIST_OF_CLIENT_PROPERTY_CONFIG = GET_LIST_OF_CLIENT_PROPERTIES(page, tokenInfo);
    
    try {
       const response = await httpRequest(API_LIST_OF_CLIENT_PROPERTY_CONFIG);
      //  console.log("********** R E S P O N S E ******** ", response.properties.data);
       if(response.success){
          setLoader(false)
          setPropertyData(response.properties.data);
          // localStorage.setItem('propertyData', JSON.stringify(response.properties.data));
       }
    } catch (error) {
        setOpen(true);
        setLoader(false);
        setError(error.message);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (rowData) => {
    navigate("/ListingsDetails", {state: {propertyDetails: rowData}})
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggleCurrency = () => {
    setCurrency((prevCurrency) => (prevCurrency === 'AED' ? 'USD' : 'AED'));
    hadleCloseCurrencyMenu()
  };

  const formatPrice = (price) => {
    if (price == null) return 'AED 0';

    if (currency === 'AED') {
      return `AED ${AmountSaperator(price)}`;
    } else {
      const priceInUSD = price / exchangeRate;
      return `USD ${AmountSaperator(priceInUSD.toFixed(2))}`;
    }
  };

  const handleToggleUnit = () => {
    setUnit((prevUnit) => (prevUnit === 'sqft' ? 'sqm' : 'sqft'));
    handleCloseAreaSizeMenu()
  };

  const formatAreaSize = (areaSize) => {
    if (unit === 'sqft') {
      return `${AmountSaperator(areaSize)} sqft`;
    } else {
      const areaInSqm = sqftToSqm(areaSize);
      return `${AmountSaperator(areaInSqm.toFixed(1))} sqm`;
    }
  };

  const fListPropertyWithKestates = async(xStatus) => {
    setListingLoader(true);
    const API_LIST_PROPERTY_WITH_KESTATES_CONFIG = LIST_PROPERTY_WITH_K_ESTATES(
      rowData.id, 
      0, 
      xStatus, 
      rowData.price, 
      rowData.images, 
      tokenInfo
    );
    
    try {
        const response = await httpRequest(API_LIST_PROPERTY_WITH_KESTATES_CONFIG);
        // console.log("**** response Listed With K Estates ****** ", response)
        if(response.success){
          setListingLoader(false);
          // setOpen(true);
          setListingError("Property Successfully Listed With K Estates")
          handleCloseMenuItem();
          getListOfProperties();
          setRowData(null);
          success();
        }

    } catch (error) {
        setOpen(true)
        setListingLoader(false);
        setListingError(error.message);
        handleCloseMenuItem();
    }
  };

  return (
    <>
    {contextHolder}
    {/* <Button onClick={success}>Success</Button> */}
      <TableContainer component={Paper} style={{ maxHeight: '500px', overflowY: 'auto' }} >
        <Table aria-label="user table" style={{ width: '100%' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#222', color: '#fff' }} >
              {/* <TableCell align='center' style={{ color: 'white' }}>id</TableCell> */}
              <TableCell align='center' style={{ color: 'white' }}>Unit</TableCell>
              <TableCell align='center' style={{ color: 'white' }}>Project</TableCell>
              <TableCell align='center' style={{ color: 'white' }}>Developer</TableCell>
              <TableCell align='center' style={{ color: 'white' }} >
                Price in {currency == 'AED'?"AED":"USD"}
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleCurrencyMenu}
                >
                  <ArrowDropDownIcon color='white' style={{ color:"white" }} />
                </IconButton>
              </TableCell>
              {/* <TableCell align='center' style={{ color: 'white' }}>DLD</TableCell> */}
              {/* <TableCell align='center' style={{ color: 'white' }}>Price Per sqft</TableCell> */}
              <TableCell align='center' style={{ color: 'white' }}>Type</TableCell>
              <TableCell align='center' style={{ color: 'white' }}>Location</TableCell>
              <TableCell align='center' style={{ color: 'white' }}>Bedrooms</TableCell>
              <TableCell align='center' style={{ color: 'white' }}>
                Area in {unit == 'sqft'?"sqft":"sqmt"}
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleAreaSizeMenu}
                >
                  <ArrowDropDownIcon color='white' style={{ color:"white" }} />
                </IconButton>
              </TableCell>
              {/* <TableCell align='center' style={{ color: 'white' }}>Status</TableCell> */}
              <TableCell align='center' style={{ color: 'white' }}>Actions</TableCell>
              {/* <TableCell align='center' style={{ color: 'white' }}>Gallery</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
          
          {propertyData.map((row, index) => (
          // {List_OF_Properties.map((row, index) => ( 
            <TableRow
                key={row.id}
                style={{ cursor: 'pointer', alignSelf: 'center' }}
                sx={{
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                {/* <TableCell align='center' onClick={() =>  handleRowClick(row)}  component="th" scope="row">{row.id}</TableCell> */}
                <TableCell align='center' onClick={() =>  handleRowClick(row)}  component="th" scope="row">{row.unit_no}</TableCell>
                <TableCell align='center' onClick={() =>  handleRowClick(row)}  component="th" scope="row">{row.title}</TableCell>
                <TableCell align='center' onClick={() =>  handleRowClick(row)}  component="th" scope="row">{`${row.developer.substring(0, 9)} ${row.developer.length > 14?'...':""} `}</TableCell>
                <TableCell align='center' onClick={() =>  handleRowClick(row)} >
                  {row.price == null ? 
                    'AED 0' 
                    : 
                    // `AED ${AmountSaperator(row.price)}`
                    `${formatPrice(row.price)}`
                  }
                  </TableCell>
                {/* <TableCell align='center' onClick={() =>  handleRowClick(row)} >{row.dld_fee == null ? 'AED 0' : `AED ${AmountSaperator(row.dld_fee)}`}</TableCell> */}
                {/* <TableCell align='center' onClick={() =>  handleRowClick(row)} >{row.price_per_area == null ? 'AED 0' : `AED ${AmountSaperator(Number(row.price_per_area).toFixed(2))}`}</TableCell> */}
                <TableCell align='center' onClick={() =>  handleRowClick(row)} >{row.property_type.charAt(0).toUpperCase() + row.property_type.slice(1)}</TableCell>
                <TableCell align='center' onClick={() =>  handleRowClick(row)} >{row.location}</TableCell>
                <TableCell align='center' onClick={() =>  handleRowClick(row)} >{row.bedrooms}</TableCell>
                <TableCell align='center' onClick={() =>  handleRowClick(row)} >
                  {/* {row.area_size + ' sqft'} */}
                  {formatAreaSize(row.area_size)}
                </TableCell>
                {/* <TableCell align='center' onClick={() =>  handleRowClick(row)} >{row.status}</TableCell> */}
                <TableCell align='center' width={"13%"}>
                  {
                    row.flag !== 0 ?
                    // row.flag == 0 ?
                      <DoneIcon color='black' />
                      :
                      // <Button onClick={() => { navigate("/Listings_Listed_With_K_Estaes", { state: { propertyDetails: row } }) }} variant='contained' size='small' style={{ backgroundColor: 'black', fontSize:'0.75rem' }} >
                      //   List with K-estate
                      // </Button>

                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleClick(event, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                  }
                </TableCell>
                {/* <TableCell align='center' style={{ display:"flex", alignItems:"center", justifyContent:"center" }} >
                  {
                    row.images.length == 0 ?
                      <img
                        key={index}
                        src={Placeholder}
                        style={{ width: '40px', height: '40px', margin: '0 5px', cursor: 'pointer', borderRadius:10 }}
                      />
                    :
                    row.images.slice(0, 1).map((image, index) => {
                      return(
                        <img
                          key={index}
                          src={`${propertyImagesUrl}/${image.name}`}
                          alt={`Thumbnail ${index}`}
                          style={{ width: '40px', height: '40px', margin: '0 5px', cursor: 'pointer', borderRadius:10 }}
                          onClick={() => handleGalleryClick(row.images)}
                        />
                      )
                    })
                  }
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {
          sLoader &&
          <div style={{ width: "100%", height:'40rem', display: 'flex', alignItems: "center", alignItems: "center", justifyContent: "center" }} >
            <Box alignSelf={'center'} sx={{ width: '30%', marginTop: '6rem', marginBottom: '6rem' }}>
              <LinearProgress color='inherit' />
              <Typography style={{textAlign:"center", fontSize: '0.85rem',color:'#666666', fontWeight: '300', fontFamily: 'Montserrat', marginTop:"0.5rem" }}>Loading...</Typography>
            </Box>
          </div>
        }
        {
          sError &&
          <div style={{ width: "100%", display: 'flex', alignItems: "center", alignItems: "center", justifyContent: "center" }} >
            <Box alignSelf={'center'} sx={{ marginTop: '6rem', marginBottom: '6rem' }}>
              <Typography variant="h3" style={{ fontSize: '1.75rem', fontFamily: 'Montserrat', marginTop: '2rem', marginBottom: '2rem' }}>
                No Properties available
              </Typography>
            </Box>
          </div>
        }
        {
          propertyData.length == 0 &&
          <div style={{ width: "100%", display: 'flex', alignItems: "center", alignItems: "center", justifyContent: "center" }} >
            <Box alignSelf={'center'} sx={{ marginTop: '6rem', marginBottom: '6rem' }}>
              <Typography variant="h3" style={{ fontSize: '1.75rem', fontFamily: 'Montserrat', marginTop: '2rem', marginBottom: '2rem' }}>
                No Properties available
              </Typography>
            </Box>
          </div>
        }
      </TableContainer>

    <ImageGalleryModal images={selectedImages} open={openModal} handleClose={handleCloseModal} />

    
    <div>
      {sListingLoader && <LoaderComp />}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenuItem}
        sx={{
          boxShadow: 'none',
        }}
      >
        <MenuItem onClick={() => fListPropertyWithKestates('Rent')}>
          <Typography
            variant="subtitle2"
            textAlign="center"
            style={{
              fontFamily: 'Montserrat',
              color: 'black',
            }}
          >
            For Rent
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => fListPropertyWithKestates('Sale')}>
          <Typography
            variant="subtitle2"
            textAlign="center"
            style={{
              fontFamily: 'Montserrat',
              color: 'black',
            }}
          >
            For Sale
          </Typography>
        </MenuItem>
      </Menu>
    </div>

    <Menu
      id="menu-appbar"
      anchorEl={currencyMenu}
      anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
      }}
      open={Boolean(currencyMenu)}
      onClose={hadleCloseCurrencyMenu}
      sx={{
        boxShadow:'none'
      }}
    >
      {
        currency == 'AED'?
          <MenuItem onClick={handleToggleCurrency} >
              <Typography variant='subtitle2' textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} > USD</Typography>
          </MenuItem>
          :
          <MenuItem onClick={handleToggleCurrency} >
              <Typography variant='subtitle2' textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} >AED</Typography>
          </MenuItem>
      }
    </Menu>

    <Menu
      id="menu-appbar"
      anchorEl={areaSizeMenu}
      anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
      }}
      open={Boolean(areaSizeMenu)}
      onClose={handleCloseAreaSizeMenu}
      sx={{
        boxShadow:'none'
      }}
    >
      {
        unit == 'sqft'?
          <MenuItem onClick={handleToggleUnit} >
              <Typography variant='subtitle2' textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} > sqmt</Typography>
          </MenuItem>
          :
          <MenuItem onClick={handleToggleUnit} >
              <Typography variant='subtitle2' textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} >Sqft</Typography>
          </MenuItem>
      }
    </Menu>

    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      message={sError ? sError : (sListingeError ? sListingeError : "")}
      anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
    />
    </>
  );
};

export default PropertyTableComp;
