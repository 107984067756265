// App.js
import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText, Divider, Container, Box, Avatar, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoImage from '../Images/KESTATES-LOGO.png'; // Import your logo 
import LogoImage2 from '../Images/k-estates-icon-logo.png'; // Import your logo image
import { Link, useNavigate } from 'react-router-dom';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MessageIcon from '@mui/icons-material/Message';
import './index.css';
import picture1 from '../Images/image1001.webp'
import { logOut } from '../Pages/Login/sliceLogin';
import storage from 'redux-persist/lib/storage';
import { useDispatch, useSelector } from 'react-redux';
import {Dropdown} from 'antd'
import { profileImageBaseUrl } from '../Pages/Server/baseUrls';
import {UsergroupDeleteOutlined} from '@ant-design/icons';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import DeveloperBoardOffOutlinedIcon from '@mui/icons-material/DeveloperBoardOffOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
function AdminHeader() {
    const tokenInfo = useSelector((state) => state.login.token);
    const userInfo = useSelector((state) => state.login.user);
    // console.log("***** User Info ***** ", userInfo);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const items = [
        {
        key: '1',
        label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
            1st menu item
            </a>
        ),
        },
    
    ];
  
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const fLogOut = () => {
    storage.removeItem() // or sessionStorage.clear();
    setDrawerOpen(false);
    dispatch(logOut({}));
    navigate("./")
}


    // Sample user data
    const user = {
        name: 'Farooq Alam',
        role: 'Admin',
        email: 'farooq@kestates.ae',
        // avatarUrl: 'https://via.placeholder.com/150', // Placeholder image URL
        avatarUrl: LogoImage2
      };
    

  return (
    <div>
      
      <AppBar position="sticky" style={{ backgroundColor:'black', height:'6.25rem', alignItems:'center', justifyContent:"center" }} >
        <Container maxWidth="l" className='headerContainer' style={{ display:'flex', flexDirection:'row', alignItems:'center',  }} >
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer}
                >
                    <MenuIcon />
                </IconButton>
                  <Link to={'./'} >
                      <img src={LogoImage} alt="Logo" className='header_style' />   
                  </Link>
            </Toolbar>
    
            <Toolbar style={{ width:'7%', display:'flex', flexDirection:'row', alignItems:"center", justifyContent:"space-between" }}  >
              <NotificationsActiveIcon />
              {
                userInfo.profile_image ? 
                    <Avatar  alt='logo' src={profileImageBaseUrl+"/"+userInfo.profile_image}/>
                    :
                    <Avatar  alt='logo' src={picture1}/>
              }
            </Toolbar>

        </Container>

        {/* <Grid container item spacing={2} style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:"space-between" }} bgcolor={'red'}  >
            <Grid sx={8} sm={8} md={8}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer}
                >
                    <MenuIcon />
                </IconButton>
                  <Link to={'./'} >
                      <img src={LogoImage} alt="Logo" style={{ width: '12.5rem', height: '3.74rem', backgroundSize:'cover'}} />   
                  </Link>
            </Grid>
    
            <Grid sx={4} sm={4} md={4} style={{ width:'7%', display:'flex', flexDirection:'row', alignItems:"center", justifyContent:"space-between" }}  >
              <NotificationsActiveIcon />
              <Avatar alt='logo' src={picture1}/>
            </Grid>

        </Grid> */}

      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#000',   width: '280px' } }} className='drawer-style' >
        <Box sx={{ p: 5, textAlign: 'center', backgroundColor: '#000'}}>
          <Avatar alt={userInfo.name} src={user.avatarUrl} sx={{ width: 100, height: 100, margin: '0 auto' }} />
          <Typography variant="subtitle1" sx={{ color: '#fff', marginTop: 1 }}>{user.name}</Typography>
          <Typography variant="body2" sx={{ color: '#fff' }}>{userInfo.role_name}</Typography>
          <Typography variant="body2" sx={{ color: '#fff' }}>{userInfo.email}</Typography>
        </Box>
        <Divider />
        <List sx={{color:'white', }}>
            <Link to="./Clients" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }} >
                <ListItem 
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                        '&:hover': {
                          backgroundColor: '#f5f5f5', 
                          color:'#000'
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <UsergroupDeleteOutlined />
                    <ListItemText primary="Agents" />
                    </Box>
                </ListItem>
            </Link>
            <Link to="./Properties" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                            '&:hover': {
                            backgroundColor: '#f5f5f5', 
                            color:'#000'
                        },
                    }}
                >   
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <ListAltOutlinedIcon />
                    <ListItemText primary="Listings" />
                    </Box>
                </ListItem>
            </Link>

            <Link to="./Owners" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }} >
                <ListItem 
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                        '&:hover': {
                          backgroundColor: '#f5f5f5', 
                          color:'#000'
                        },
                    }}
                >
               <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <ContactMailOutlinedIcon />
          <ListItemText primary="Owners" />
        </Box>
                </ListItem>
            </Link>

            <Link to="./Requests" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }} >
                <ListItem 
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                        '&:hover': {
                          backgroundColor: '#f5f5f5', 
                          color:'#000'
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <EmailOutlinedIcon  />
                    <ListItemText primary="Requests" />
                    </Box>
                </ListItem>
            </Link>

          

            <Link to="./Viewings" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }} >
                <ListItem 
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                        '&:hover': {
                          backgroundColor: '#f5f5f5', 
                          color:'#000'
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CameraAltOutlinedIcon />
                    <ListItemText primary="Viewings" />
                    </Box>
                </ListItem>
            </Link> 

            <Link to="./launches" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                            '&:hover': {
                            backgroundColor: '#f5f5f5', 
                            color:'#000'
                        },
                    }}
                >   
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <ListAltOutlinedIcon />
                    <ListItemText primary="New Launches" />
                    </Box>
                </ListItem>
            </Link>

            <Link to="./Deals" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                            '&:hover': {
                            backgroundColor: '#f5f5f5', 
                            color:'#000'
                        },
                    }}
                >   
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <ListAltOutlinedIcon />
                    <ListItemText primary="Deals" />
                    </Box>
                </ListItem>
            </Link>

            
            <Link to="./kycs" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                            '&:hover': {
                            backgroundColor: '#f5f5f5', 
                            color:'#000'
                        },
                    }}
                >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
   
                <LanguageOutlinedIcon />
                    <ListItemText primary="Kyc" />
                    </Box>
                </ListItem>
                
            </Link>

            <Link to="./kyps" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                            '&:hover': {
                            backgroundColor: '#f5f5f5', 
                            color:'#000'
                        },
                    }}
                >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
   
                <LanguageOutlinedIcon />
                    <ListItemText primary="Kyp" />
                    </Box>
                </ListItem>
                
            </Link>
            <Link to="./Locations" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                            '&:hover': {
                            backgroundColor: '#f5f5f5', 
                            color:'#000'
                        },
                    }}
                >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
   
                <LanguageOutlinedIcon />
                    <ListItemText primary="Locations" />
                    </Box>
                </ListItem>
                
            </Link>

            <Link to="./Developers" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                            '&:hover': {
                            backgroundColor: '#f5f5f5', 
                            color:'#000'
                        },
                    }}
                >   
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

                <DeveloperBoardOffOutlinedIcon />
                    <ListItemText primary="Developers" />
                    </Box>
                </ListItem>
                
            </Link>

            <Link to="./PropertyTypes" onClick={toggleDrawer} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem
                    style={{ cursor: 'pointer', alignSelf:'center' }} 
                    sx={{
                            '&:hover': {
                            backgroundColor: '#f5f5f5', 
                            color:'#000'
                        },
                    }}
                >   
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

                <BusinessOutlinedIcon />
                    <ListItemText primary="PropertyTypes" />
                    </Box>
                </ListItem>
                
            </Link>
           
            <Link to="./" onClick={fLogOut} style={{ textDecoration: 'none', color: 'inherit'}}>
          
                <ListItem
                    style={{ cursor: 'pointer', alignSelf:'center',marginTop:'5px'  }} 
                    sx={{
                            '&:hover': {
                            backgroundColor: '#f5f5f5', 
                            color:'#000'
                        },
                    }}
                >  
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                     <LogoutOutlinedIcon />
                    <ListItemText primary="LogOut" />
                    </Box> 
                </ListItem>
            </Link>
        </List>
      </Drawer>

      <div style={{ padding: '20px' }}>
        {/* <Typography variant="h4">Main Content</Typography> */}
        {/* Your main content goes here */}
      </div>

    </div>
  );
}

export default AdminHeader;
