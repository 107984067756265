import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Local 

import { profileImageBaseUrl } from '../Pages/Server/baseUrls';
// import LogoImage from '../Images/2.png'; // Import your logo image
// import LogoImage from '../Images/22.png'; // Import your logo image
import LogoImage from '../Images/KESTATES-LOGO.png'; // Import your logo image
import { logOut } from '../Pages/Login/sliceLogin';
import './index.css'

const pages = ['Home', 'Listings'];
const settings = ['Profile', 'Setting', 'Logout'];

function ScreenHeader({userRole}) {
    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const userInfo = useSelector((state) => state.login.user);

    // const theme = useTheme();
    // const isXs = useMediaQuery(theme.breakpoints.down('xs'));

    // const profileImage = profileImageBaseUrl+"/"+userInfo.profile_image;

    // const [anchorElNav, setAnchorElNav] = React.useState(null);
    // const [anchorElUser, setAnchorElUser] = React.useState(null);

    // const handleOpenNavMenu = (event) => {
    //     setAnchorElNav(event.currentTarget);
    // };
    // const handleOpenUserMenu = (event) => {
    //     setAnchorElUser(event.currentTarget);
    // };

    // const handleCloseNavMenu = () => {
    //     setAnchorElNav(null);
    // };

    // const handleCloseUserMenu = () => {
    //     setAnchorElUser(null);
    // };

    // const fLogOut = () => {
    //     storage.removeItem() // or sessionStorage.clear();
    //     // localStorage.clear();
    //     dispatch(logOut({}));
    //     navigate("./")
    // }

    return (
        <AppBar position="sticky"  style={{ backgroundColor:'black', height:'5rem', alignItems:'center', justifyContent:"center" }} >  
            {/* <Container maxWidth="xl"> */}
            <Container maxWidth="l">
                <Toolbar>
                    
                    <Link to={'./'} >
                        <img src={LogoImage} alt="Logo" style={{ width: '12.5rem', height: '3.74rem', backgroundSize:'cover'}} />   
                    </Link>
                
                    <Box sx={{ flexGrow: 1, /*display: { xs: 'none', md: 'flex' } */ }}>
                       
                    </Box>
                   
                   <Box sx={{ flexGrow: 0 }}>
                        <div style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-start" }} >
                            {/* <div className='newMenu' >
                                <Typography style={{ fontSize:'1rem', fontWeight: '400', fontFamily:'Montserrat'}} >
                                    { !userInfo.name ? " " : userInfo.name}
                                </Typography>
                                <Typography style={{ fontSize:'0.75rem', fontWeight: '300', fontFamily:'Montserrat'}} >
                                    { !userInfo.email ? " " : userInfo.email}
                                </Typography>
                            </div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                {
                                    profileImage ?
                                        <Avatar src={profileImage} style={{ width:'3rem', height:'3rem' }} />
                                        :
                                        <div className='avatar-1'  style={{ width: '2.5rem', height: '2.5rem', borderRadius:100, border:'2px solid #bbb', display:"flex", alignItems:"center", justifyContent:"center" }} >
                                            <Typography style={{ fontSize:'1rem', fontWeight: '400', fontFamily:'Montserrat'}} >
                                                { !userInfo.name ? " " : userInfo.name.slice(0,2)}
                                            </Typography>
                                        </div>
                                }
                                <ArrowDropDownIcon style={{ width:'1.75rem', height:'1.75rem', color:'#bbb' }} />
                            </IconButton> */}
                        </div>
                        {/* <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            >
                            <MenuItem onClick={() => {
                                navigate('./')
                                handleCloseNavMenu()
                            }}>
                                <Typography textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} >Home</Typography>
                            </MenuItem>
                            <MenuItem onClick={ () => {
                                navigate('./Account')
                                handleCloseNavMenu()
                            }}>
                                <Typography textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} >Account</Typography>
                            </MenuItem>

                            <MenuItem onClick={ () => {
                                navigate('./Setting')
                                handleCloseNavMenu()
                            }}>
                                <Typography textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} >Setting</Typography>
                            </MenuItem>

                            <MenuItem onClick={ () => {
                                navigate('./Reset_Password')
                                handleCloseNavMenu()
                            }}>
                                <Typography textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} >Reset Password</Typography>
                            </MenuItem>

                            <MenuItem onClick={() => fLogOut()} >
                                <Typography textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} >Logout</Typography>
                            </MenuItem>
                        </Menu> */}
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ScreenHeader;
