import React from 'react'
import './kyccomp.css'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { baseUrl, signatureImageBaseUrl } from '../Pages/Server/baseUrls';
import { useSelector } from 'react-redux';
import axios from 'axios';
export default function KycComp({updateData}) {

    const token = useSelector((state) => state.login.token);
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token as a Bearer token
      },
    };

    const allSources = [
        { source: "Salary" },
        { source: "Business" },
        { source: "Investment" },
        { source: "Inheritance" },
        { source: "Rental" },
        { source: "FamilySupport" },
        { source: "SaleofProperty" },
        { source: "SaleofCompanyBusiness" },
        { source: "Other" }
    ];

    const isSourceInDatabase = (source) => {
       
        return updateData.sources.some(dbSource => dbSource.source === source);
    };

 
    const loadImageAsBase64 = async (imageUrl) => {
        const url = `${baseUrl}/api/getSignature?url=${encodeURIComponent(imageUrl)}`;
        
        const response = await axios.get(url, axiosConfig);
      
       return response.data.base64Image
  
    };
    const downloadPdf = async() => {
        const input1 = document.getElementById('page1-div');
        const input2 = document.getElementById('page2-div');
        const imageUrl = signatureImageBaseUrl+'/'+updateData.signature;
        const imageData = await loadImageAsBase64(imageUrl);

        const pxToMm = 0.26;

        // Example CSS values
        const cssTop = 0.9; // CSS top in pixels
        const cssLeft = 225; // CSS left in pixels
        const cssWidth = 120; // CSS width in pixels
        const cssHeight = 80; // CSS height in pixels
    
        // Convert to PDF units
        const pdfTop = cssTop * pxToMm;
        const pdfLeft = cssLeft * pxToMm;
        const pdfWidth = cssWidth * pxToMm;
        const pdfHeight = cssHeight * pxToMm;
       
        const pageHeight = 1122; // A4 page height in pixels (at 96dpi, jsPDF uses 72dpi so 1122 is correct for A4)
        const pdf = new jsPDF('p', 'pt', 'a4');
        

        const createPdfPage = async (input, startY = 0) => {
            const inputHeight = input.scrollHeight;
            const totalPages = Math.ceil(inputHeight / pageHeight);
            
    
            for (let i = 0; i < totalPages; i++) {
                const canvas = await html2canvas(input, {
                    scale: 2,
                    scrollX: 0,
                    scrollY: -pageHeight * i,
                    windowWidth: input.scrollWidth,
                    windowHeight: pageHeight,
                    useCORS: false
                });
    
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 595.28; // A4 width in points
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
                if (startY > 0 || i > 0) {
                    pdf.addPage();
                }
               
                pdf.addImage(imageData, 'PNG', pdfLeft, pdfTop, cssWidth, cssHeight);
                pdf.addImage(imgData, 'PNG', 0, startY + -pageHeight * i, imgWidth, imgHeight);
            }
        };
    
        await createPdfPage(input1);
        await createPdfPage(input2, 0);
    
      await  pdf.save('kyc.pdf');
    };
    
  return (
   <div>
    <button onClick={downloadPdf}>Download as PDF</button>
    <div  >
  <title />
  <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
  <br />
  <style type="text/css" dangerouslySetInnerHTML={{__html: "\n    <!--\n        p {margin: 0; padding: 0;}\t.ft10{font-size:11px;font-family:Times;color:#000000;}\n        .ft11{font-size:11px;font-family:Times;color:#7c7c7c;}\n        .ft12{font-size:11px;font-family:Times;color:#000000;}\n        .ft13{font-size:16px;font-family:Times;color:#000000;}\n        .ft14{font-size:22px;font-family:Times;color:#000000;}\n        .ft15{font-size:9px;font-family:Times;color:#653265;}\n        .ft16{font-size:9px;font-family:Times;color:#000000;}\n        .ft17{font-size:5px;font-family:Times;color:#000000;}\n        .ft18{font-size:2px;font-family:Times;color:#000000;}\n        .ft19{font-size:9px;font-family:Times;color:#ffffff;}\n        .ft110{font-size:9px;font-family:Times;color:#000000;}\n        .ft111{font-size:9px;font-family:Times;color:#d9d9d9;}\n        .ft112{font-size:8px;font-family:Times;color:#000000;}\n        .ft113{font-size:9px;font-family:Times;color:#bebebe;}\n        .ft114{font-size:21px;font-family:Times;color:#000000;}\n        .ft115{font-size:9px;line-height:21px;font-family:Times;color:#000000;}\n    -->\n    " }} />
  <div id='page1-div' style={{position: 'relative', width: 892, height: 1261}}>
    <img width={892} height={1261} src="target001.png" alt="background image" />
    <p style={{position: 'absolute', top: 1214, left: 398, whiteSpace: 'nowrap'}} className="ft10"><b>1&nbsp;|&nbsp;</b></p>
    <p style={{position: 'absolute', top: 1215, left: 416, whiteSpace: 'nowrap'}} className="ft11">P&nbsp;a&nbsp;g&nbsp;e</p>
    <p style={{position: 'absolute', top: 1215, left: 454, whiteSpace: 'nowrap'}} className="ft12">&nbsp;</p>
    <p style={{position: 'absolute', top: 16, left: 43, whiteSpace: 'nowrap'}} className="ft13">&nbsp;</p>
    <p style={{position: 'absolute', top: 41, left: 43, whiteSpace: 'nowrap'}} className="ft14">&nbsp;</p>
    <p style={{position: 'absolute', top: 81, left: 43, whiteSpace: 'nowrap'}} className="ft14">&nbsp;</p>
    <p style={{position: 'absolute', top: 81, left: 197, whiteSpace: 'nowrap'}} className="ft14">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <p style={{position: 'absolute', top: 120, left: 220, whiteSpace: 'nowrap'}} className="ft14">&nbsp;&nbsp;&nbsp;</p>
    <p style={{position: 'absolute', top: 1220, left: 43, whiteSpace: 'nowrap'}} className="ft13">&nbsp;</p>
    <p style={{position: 'absolute', top: 284, left: 43, whiteSpace: 'nowrap'}} className="ft15">&nbsp;</p>
    <p style={{position: 'absolute', top: 446, left: 43, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <p style={{position: 'absolute', top: 586, left: 43, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <p style={{position: 'absolute', top: 926, left: 43, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <p style={{position: 'absolute', top: 1081, left: 43, whiteSpace: 'nowrap'}} className="ft17">&nbsp;</p>
    <p style={{position: 'absolute', top: 1092, left: 43, whiteSpace: 'nowrap'}} className="ft18">&nbsp;</p>
    <p style={{position: 'absolute', top: 1177, left: 43, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <p style={{position: 'absolute', top: 194, left: 61, whiteSpace: 'nowrap'}} className="ft19"><b>OFFICE&nbsp;USE&nbsp;ONLY</b></p>
    <p style={{position: 'absolute', top: 194, left: 164, whiteSpace: 'nowrap'}} className="ft110"><b>&nbsp;</b></p>
    <p style={{position: 'absolute', top: 217, left: 61, whiteSpace: 'nowrap'}} className="ft16">Client&nbsp;Status&nbsp;</p>
    <p style={{position: 'absolute', top: 217, left: 210, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <div style={{position: 'absolute', top: 217, left: 229, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.client_status=="New"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">New&nbsp;</p>
            </div>
    <p style={{position: 'absolute', top: 217, left: 316, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <div style={{position: 'absolute', top: 217, left: 335, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.client_status=="Existing"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Existing&nbsp;</p>
            </div>
    <p style={{position: 'absolute', top: 217, left: 411, whiteSpace: 'nowrap'}} className="ft16">Proposed&nbsp;Ownership/Tenancy&nbsp;</p>
    <p style={{position: 'absolute', top: 217, left: 625, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <div style={{position: 'absolute', top: 217, left: 645, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.ownership=="Single"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Single&nbsp;</p>
            </div>
    <p style={{position: 'absolute', top: 217, left: 757, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <div style={{position: 'absolute', top: 217, left: 776, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.ownership=="Joint"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Joint&nbsp;</p>
            </div>
    <p style={{position: 'absolute', top: 239, left: 61, whiteSpace: 'nowrap'}} className="ft16">Customer&nbsp;Reference&nbsp;No.&nbsp;</p>
    <p style={{position: 'absolute', top: 239, left: 256, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <p style={{position: 'absolute', top: 237, left: 411, whiteSpace: 'nowrap'}} className="ft16">Agent’s&nbsp;Name&nbsp;</p>
    <p style={{position: 'absolute', top: 239, left: 602, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <p style={{position: 'absolute', top: 262, left: 61, whiteSpace: 'nowrap'}} className="ft16">Property&nbsp;Details&nbsp;</p>
    <p style={{position: 'absolute', top: 262, left: 256, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <p style={{position: 'absolute', top: 311, left: 61, whiteSpace: 'nowrap'}} className="ft19"><b>PERSONAL&nbsp;INFORMATION</b></p>
    <p style={{position: 'absolute', top: 311, left: 216, whiteSpace: 'nowrap'}} className="ft110"><b>&nbsp;</b></p>
    <p style={{position: 'absolute', top: 334, left: 61, whiteSpace: 'nowrap'}} className="ft16">Title&nbsp;</p>
            <div class="checkbox-container">
                
            <div class="checkbox">✓&nbsp;</div>
            <p class="ft16">Mr.&nbsp;</p>
        </div>

  
    
    <p style={{position: 'absolute', top: 334, left: 303, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <div style={{position: 'absolute', top: 334, left: 325, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                    {updateData.title=="Mrs."?"✓":""}
                    
                    &nbsp;</div>
                <p  class="ft16">Mrs.&nbsp;</p>
            </div>
  
    <p style={{position: 'absolute', top: 334, left: 397, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <div style={{position: 'absolute', top: 334, left: 413, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.title=="Ms."?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Ms.&nbsp;</p>
            </div>

    <p style={{position: 'absolute', top: 334, left: 491, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <div style={{position: 'absolute', top: 334, left: 507, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.title=="Dr."?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Dr.&nbsp;</p>
            </div>
    <p style={{position: 'absolute', top: 334, left: 585, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <div style={{position: 'absolute', top: 334, left: 601, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.title=="Other."?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Other.&nbsp;</p>
            </div>
    <p style={{position: 'absolute', top: 334, left: 660, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <p style={{position: 'absolute', top: 356, left: 61, whiteSpace: 'nowrap'}} className="ft16">Surname&nbsp;</p>
    <p style={{position: 'absolute', top: 356, left: 184, whiteSpace: 'nowrap'}} className="ft16">{updateData.surname}&nbsp;</p>
    <p style={{position: 'absolute', top: 356, left: 412, whiteSpace: 'nowrap'}} className="ft16">Forenames&nbsp;</p>
    <p style={{position: 'absolute', top: 356, left: 571, whiteSpace: 'nowrap'}} className="ft16">{updateData.forenames}&nbsp;</p>
    <p style={{position: 'absolute', top: 379, left: 61, whiteSpace: 'nowrap'}} className="ft16">Nationality&nbsp;</p>
    <p style={{position: 'absolute', top: 379, left: 184, whiteSpace: 'nowrap'}} className="ft16">{updateData.nationality}&nbsp;</p>
    <p style={{position: 'absolute', top: 379, left: 412, whiteSpace: 'nowrap'}} className="ft16">Gender&nbsp;</p>
    <div style={{position: 'absolute', top: 379, left: 591, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.gender=="Male"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Male&nbsp;</p>
            </div>
            <div style={{position: 'absolute', top: 379, left: 672, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.gender=="Female"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Female&nbsp;</p>
            </div>
            <div style={{position: 'absolute', top: 379, left: 765, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.gender=="Other"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Other&nbsp;</p>
            </div>
   
    <p style={{position: 'absolute', top: 402, left: 61, whiteSpace: 'nowrap'}} className="ft16">2</p>
    <p style={{position: 'absolute', top: 399, left: 67, whiteSpace: 'nowrap'}} className="ft17">nd</p>
    <p style={{position: 'absolute', top: 402, left: 77, whiteSpace: 'nowrap'}} className="ft16">&nbsp;Nationality&nbsp;</p>
    <p style={{position: 'absolute', top: 402, left: 184, whiteSpace: 'nowrap'}} className="ft16">{updateData.second_nationality}&nbsp;</p>
    <p style={{position: 'absolute', top: 402, left: 412, whiteSpace: 'nowrap'}} className="ft16">Date&nbsp;of&nbsp;Birth&nbsp;</p>
    <p style={{position: 'absolute', top: 402, left: 575, whiteSpace: 'nowrap'}} className="ft16">{updateData.dob}&nbsp;</p>
    <p style={{position: 'absolute', top: 424, left: 61, whiteSpace: 'nowrap'}} className="ft16">Country&nbsp;of&nbsp;Birth&nbsp;</p>
    <p style={{position: 'absolute', top: 424, left: 184, whiteSpace: 'nowrap'}} className="ft16">{updateData.birth_country}&nbsp;</p>
    <p style={{position: 'absolute', top: 424, left: 412, whiteSpace: 'nowrap'}} className="ft16">Country&nbsp;of&nbsp;Residence&nbsp;</p>
    <p style={{position: 'absolute', top: 424, left: 571, whiteSpace: 'nowrap'}} className="ft16">{updateData.residence_country}&nbsp;</p>
    <p style={{position: 'absolute', top: 473, left: 62, whiteSpace: 'nowrap'}} className="ft19"><b>ADDRESS&nbsp;DETAILS</b></p>
    <p style={{position: 'absolute', top: 473, left: 169, whiteSpace: 'nowrap'}} className="ft110"><b>&nbsp;</b></p>
    <p style={{position: 'absolute', top: 496, left: 62, whiteSpace: 'nowrap'}} className="ft16">Address&nbsp;(Line&nbsp;1)&nbsp;</p>
    <p style={{position: 'absolute', top: 496, left: 208, whiteSpace: 'nowrap'}} className="ft16">{updateData.address_line1}&nbsp;</p>
    <p style={{position: 'absolute', top: 518, left: 62, whiteSpace: 'nowrap'}} className="ft16">Address&nbsp;(Line&nbsp;2)&nbsp;</p>
    <p style={{position: 'absolute', top: 518, left: 208, whiteSpace: 'nowrap'}} className="ft16">{updateData.address_line2}&nbsp;</p>
    <p style={{position: 'absolute', top: 541, left: 62, whiteSpace: 'nowrap'}} className="ft16">City&nbsp;</p>
    <p style={{position: 'absolute', top: 541, left: 208, whiteSpace: 'nowrap'}} className="ft16">{updateData.city}&nbsp;</p>
    <p style={{position: 'absolute', top: 541, left: 416, whiteSpace: 'nowrap'}} className="ft16">PO&nbsp;Box&nbsp;/&nbsp;Postal&nbsp;Code&nbsp;</p>
    <p style={{position: 'absolute', top: 541, left: 591, whiteSpace: 'nowrap'}} className="ft16">{updateData.postal_code}&nbsp;</p>
    <p style={{position: 'absolute', top: 564, left: 62, whiteSpace: 'nowrap'}} className="ft16">State&nbsp;/&nbsp;Province&nbsp;</p>
    <p style={{position: 'absolute', top: 564, left: 208, whiteSpace: 'nowrap'}} className="ft16">{updateData.state_province}&nbsp;</p>
    <p style={{position: 'absolute', top: 564, left: 416, whiteSpace: 'nowrap'}} className="ft16">Country&nbsp;</p>
    <p style={{position: 'absolute', top: 564, left: 591, whiteSpace: 'nowrap'}} className="ft16">{updateData.country}&nbsp;</p>
    <p style={{position: 'absolute', top: 613, left: 62, whiteSpace: 'nowrap'}} className="ft19"><b>CONTACT&nbsp;DETAILS</b></p>
    <p style={{position: 'absolute', top: 613, left: 172, whiteSpace: 'nowrap'}} className="ft110"><b>&nbsp;</b></p>
    <p style={{position: 'absolute', top: 635, left: 62, whiteSpace: 'nowrap'}} className="ft16">Telephone&nbsp;(Res.)&nbsp;</p>
    <p style={{position: 'absolute', top: 635, left: 208, whiteSpace: 'nowrap'}} className="ft16">{updateData.telephone_res}&nbsp;</p>
    <p style={{position: 'absolute', top: 635, left: 417, whiteSpace: 'nowrap'}} className="ft16">Telephone&nbsp;(Off.)&nbsp;</p>
    <p style={{position: 'absolute', top: 635, left: 591, whiteSpace: 'nowrap'}} className="ft16">{updateData.telephone_off}&nbsp;</p>
    <p style={{position: 'absolute', top: 658, left: 62, whiteSpace: 'nowrap'}} className="ft16">Mobile&nbsp;(Personal)&nbsp;</p>
    <p style={{position: 'absolute', top: 658, left: 208, whiteSpace: 'nowrap'}} className="ft16">&nbsp;{updateData.mobile_personal}</p>
    <p style={{position: 'absolute', top: 658, left: 417, whiteSpace: 'nowrap'}} className="ft16">Mobile&nbsp;(Business)&nbsp;</p>
    <p style={{position: 'absolute', top: 658, left: 591, whiteSpace: 'nowrap'}} className="ft16">{updateData.mobile_business}&nbsp;</p>
    <p style={{position: 'absolute', top: 681, left: 62, whiteSpace: 'nowrap'}} className="ft16">Personal&nbsp;Email&nbsp;</p>
    <p style={{position: 'absolute', top: 681, left: 208, whiteSpace: 'nowrap'}} className="ft16">{updateData.personal_email}&nbsp;</p>
    <p style={{position: 'absolute', top: 703, left: 62, whiteSpace: 'nowrap'}} className="ft16">Business&nbsp;Email&nbsp;</p>
    <p style={{position: 'absolute', top: 703, left: 208, whiteSpace: 'nowrap'}} className="ft16">{updateData.business_email}&nbsp;</p>
    <p style={{position: 'absolute', top: 745, left: 62, whiteSpace: 'nowrap'}} className="ft19"><b>IDENTIFICATION&nbsp;DETAILS</b></p>
    <p style={{position: 'absolute', top: 745, left: 211, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <p style={{position: 'absolute', top: 767, left: 62, whiteSpace: 'nowrap'}} className="ft16">Passport&nbsp;Details&nbsp;</p>
    <p style={{position: 'absolute', top: 790, left: 62, whiteSpace: 'nowrap'}} className="ft16">Passport&nbsp;No.&nbsp;</p>
    <p style={{position: 'absolute', top: 790, left: 185, whiteSpace: 'nowrap'}} className="ft16">{updateData.passport_no}&nbsp;</p>
    <p style={{position: 'absolute', top: 790, left: 413, whiteSpace: 'nowrap'}} className="ft16">Passport&nbsp;Type&nbsp;</p>
    <p style={{position: 'absolute', top: 791, left: 546, whiteSpace: 'nowrap'}} className="ft112">&nbsp;</p>
    <div style={{position: 'absolute', top: 791, left: 562, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.passport_type=="Regular"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Regular&nbsp;</p>
            </div>
            <div style={{position: 'absolute', top: 791, left: 639, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.passport_type=="Diplomatic"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Diplomatic&nbsp;</p>
            </div>
    
    <p style={{position: 'absolute', top: 791, left: 745, whiteSpace: 'nowrap'}} className="ft112">&nbsp;</p>
    <div style={{position: 'absolute', top: 791, left: 762, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.passport_type=="Other"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Other&nbsp;</p>
            </div>
    <p style={{position: 'absolute', top: 813, left: 62, whiteSpace: 'nowrap'}} className="ft16">Date&nbsp;of&nbsp;Issue&nbsp;</p>
    <p style={{position: 'absolute', top: 813, left: 185, whiteSpace: 'nowrap'}} className="ft16">{updateData.passport_issue_date}&nbsp;</p>
    <p style={{position: 'absolute', top: 813, left: 413, whiteSpace: 'nowrap'}} className="ft16">Date&nbsp;of&nbsp;Expiry&nbsp;</p>
    <p style={{position: 'absolute', top: 813, left: 538, whiteSpace: 'nowrap'}} className="ft16">{updateData.passport_expiry_date}&nbsp;</p>
    <p style={{position: 'absolute', top: 835, left: 62, whiteSpace: 'nowrap'}} className="ft16">Place&nbsp;of&nbsp;Issue&nbsp;</p>
    <p style={{position: 'absolute', top: 835, left: 185, whiteSpace: 'nowrap'}} className="ft16">{updateData.passport_place_issue}&nbsp;</p>
    <p style={{position: 'absolute', top: 835, left: 413, whiteSpace: 'nowrap'}} className="ft16">Country&nbsp;of&nbsp;Issue&nbsp;</p>
    <p style={{position: 'absolute', top: 835, left: 529, whiteSpace: 'nowrap'}} className="ft16">{updateData.passport_country_issue}&nbsp;</p>
    <p style={{position: 'absolute', top: 858, left: 62, whiteSpace: 'nowrap'}} className="ft16">Emirates&nbsp;/&nbsp;National&nbsp;ID&nbsp;Details&nbsp;(if&nbsp;Emirates&nbsp;ID&nbsp;is&nbsp;not&nbsp;available)&nbsp;</p>
    <p style={{position: 'absolute', top: 881, left: 62, whiteSpace: 'nowrap'}} className="ft16">National&nbsp;ID&nbsp;No.&nbsp;</p>
    <p style={{position: 'absolute', top: 881, left: 185, whiteSpace: 'nowrap'}} className="ft16">{updateData.national_id_no}&nbsp;</p>
    <p style={{position: 'absolute', top: 881, left: 413, whiteSpace: 'nowrap'}} className="ft16">Date&nbsp;of&nbsp;Expiry&nbsp;</p>
    <p style={{position: 'absolute', top: 881, left: 538, whiteSpace: 'nowrap'}} className="ft16">{updateData.national_id_expiry_date}&nbsp;</p>
    <p style={{position: 'absolute', top: 904, left: 62, whiteSpace: 'nowrap'}} className="ft16">Issuing&nbsp;Authority&nbsp;</p>
    <p style={{position: 'absolute', top: 904, left: 185, whiteSpace: 'nowrap'}} className="ft16">{updateData.issuing_authority}&nbsp;</p>
    <p style={{position: 'absolute', top: 904, left: 413, whiteSpace: 'nowrap'}} className="ft16">Country&nbsp;</p>
    <p style={{position: 'absolute', top: 904, left: 680, whiteSpace: 'nowrap'}} className="ft113">{updateData.national_id_country}&nbsp;</p>
    <p style={{position: 'absolute', top: 954, left: 62, whiteSpace: 'nowrap'}} className="ft19"><b>POLITICALLY&nbsp;EXPOSED&nbsp;PERSON&nbsp;(PEP)</b></p>
    <p style={{position: 'absolute', top: 954, left: 279, whiteSpace: 'nowrap'}} className="ft110"><b>&nbsp;</b></p>
    <p style={{position: 'absolute', top: 978, left: 62, whiteSpace: 'nowrap'}} className="ft16">Politically&nbsp;Exposed&nbsp;</p>
    <div style={{position: 'absolute', top: 978, left: 260, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.pep=="Yes"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Yes&nbsp;</p>
            </div>
            <div style={{position: 'absolute', top: 978, left: 336, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.pep=="No"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">No&nbsp;</p>
            </div>
   
    <p style={{position: 'absolute', top: 978, left: 393, whiteSpace: 'nowrap'}} className="ft16">Position&nbsp;</p>
    <p style={{position: 'absolute', top: 978, left: 504, whiteSpace: 'nowrap'}} className="ft16">{updateData.pep_position}&nbsp;</p>
    <p style={{position: 'absolute', top: 1000, left: 62, whiteSpace: 'nowrap'}} className="ft16">Related&nbsp;to&nbsp;Politically&nbsp;</p>
    <p style={{position: 'absolute', top: 1020, left: 62, whiteSpace: 'nowrap'}} className="ft16">Exposed&nbsp;</p>
    <div style={{position: 'absolute', top: 1011, left: 260, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.ubo=="Yes"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Yes&nbsp;</p>
            </div>
            <div style={{position: 'absolute', top: 1011, left: 336, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.ubo=="No"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">No&nbsp;</p>
            </div>
    <p style={{position: 'absolute', top: 1008, left: 393, whiteSpace: 'nowrap'}} className="ft16">Person’s&nbsp;Name&nbsp;</p>
    <p style={{position: 'absolute', top: 1011, left: 504, whiteSpace: 'nowrap'}} className="ft16">{updateData.related_pep_name}&nbsp;</p>
    <p style={{position: 'absolute', top: 1049, left: 62, whiteSpace: 'nowrap'}} className="ft16">Person’s&nbsp;Position&nbsp;</p>
    <p style={{position: 'absolute', top: 1052, left: 240, whiteSpace: 'nowrap'}} className="ft16">{updateData.related_pep_position}&nbsp;</p>
    <p style={{position: 'absolute', top: 1039, left: 393, whiteSpace: 'nowrap'}} className="ft115">Person’s&nbsp;<br />Country&nbsp;</p>
    <p style={{position: 'absolute', top: 1052, left: 504, whiteSpace: 'nowrap'}} className="ft16">{updateData.related_pep_country}&nbsp;</p>
    <p style={{position: 'absolute', top: 1109, left: 62, whiteSpace: 'nowrap'}} className="ft19"><b>ULTIMATE&nbsp;BENEFICIARY&nbsp;OWNER&nbsp;(UBO)&nbsp;</b></p>
    <p style={{position: 'absolute', top: 1109, left: 293, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <p style={{position: 'absolute', top: 1132, left: 62, whiteSpace: 'nowrap'}} className="ft16">Are&nbsp;you&nbsp;the&nbsp;Ultimate&nbsp;Beneficiary&nbsp;Owner&nbsp;of&nbsp;this&nbsp;property?&nbsp;&nbsp;</p>
    <p style={{position: 'absolute', top: 1132, left: 421, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <div style={{position: 'absolute', top: 1132, left: 440, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.ubo=="Yes"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">Yes&nbsp;</p>
            </div>
   
    <p style={{position: 'absolute', top: 1132, left: 531, whiteSpace: 'nowrap'}} className="ft16">&nbsp;</p>
    <div style={{position: 'absolute', top: 1132, left: 551, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {updateData.ubo=="No"?"✓":""}
                    &nbsp;</div>
                <p  class="ft16">No&nbsp;</p>
            </div>
   
    <p style={{position: 'absolute', top: 1155, left: 62, whiteSpace: 'nowrap'}} className="ft16">If&nbsp;not,&nbsp;provide&nbsp;the&nbsp;name&nbsp;of&nbsp;the&nbsp;UBO</p>
    <p style={{position: 'absolute', top: 1160, left: 262, whiteSpace: 'nowrap'}} className="ft17">{updateData.ubo_name}&nbsp;</p>
    <p style={{position: 'absolute', top: 1160, left: 336, whiteSpace: 'nowrap'}} className="ft17">&nbsp;</p>
    <p style={{position: 'absolute', top: 124, left: 244, whiteSpace: 'nowrap'}} className="ft114">Know Your Customer (Individual) Form</p>
  </div>
  <title />
  <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
  <br />
  <style type="text/css" dangerouslySetInnerHTML={{__html: "\n    <!--\n        p {margin: 0; padding: 0;}\t.ft20{font-size:11px;font-family:Times;color:#000000;}\n        .ft21{font-size:11px;font-family:Times;color:#7c7c7c;}\n        .ft22{font-size:11px;font-family:Times;color:#000000;}\n        .ft23{font-size:16px;font-family:Times;color:#000000;}\n        .ft24{font-size:22px;font-family:Times;color:#000000;}\n        .ft25{font-size:9px;font-family:Times;color:#000000;}\n        .ft26{font-size:9px;font-family:Times;color:#000000;}\n        .ft27{font-size:9px;font-family:Times;color:#ffffff;}\n        .ft28{font-size:5px;font-family:Times;color:#000000;}\n        .ft29{font-size:9px;font-family:Times;color:#f2f2f2;}\n        .ft210{font-size:21px;font-family:Times;color:#000000;}\n        .ft211{font-size:10px;font-family:Helvetica;color:#212121;}\n        .ft212{font-size:9px;line-height:17px;font-family:Times;color:#000000;}\n    -->\n    " }} />
  <div id="page2-div" style={{position: 'relative', width: 892, height: 1261}}>
    <img width={892} height={1261} src="target002.png" alt="background image" />
    <p style={{position: 'absolute', top: 1214, left: 398, whiteSpace: 'nowrap'}} className="ft20"><b>2&nbsp;|&nbsp;</b></p>
    <p style={{position: 'absolute', top: 1215, left: 416, whiteSpace: 'nowrap'}} className="ft21">P&nbsp;a&nbsp;g&nbsp;e</p>
    <p style={{position: 'absolute', top: 1215, left: 454, whiteSpace: 'nowrap'}} className="ft22">&nbsp;</p>
    <p style={{position: 'absolute', top: 16, left: 43, whiteSpace: 'nowrap'}} className="ft23">&nbsp;</p>
    <p style={{position: 'absolute', top: 41, left: 43, whiteSpace: 'nowrap'}} className="ft24">&nbsp;</p>
    <p style={{position: 'absolute', top: 81, left: 43, whiteSpace: 'nowrap'}} className="ft24">&nbsp;</p>
    <p style={{position: 'absolute', top: 81, left: 197, whiteSpace: 'nowrap'}} className="ft24">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <p style={{position: 'absolute', top: 120, left: 220, whiteSpace: 'nowrap'}} className="ft24">&nbsp;&nbsp;&nbsp;</p>
    <p style={{position: 'absolute', top: 1220, left: 43, whiteSpace: 'nowrap'}} className="ft23">&nbsp;</p>
    <p style={{position: 'absolute', top: 352, left: 43, whiteSpace: 'nowrap'}} className="ft25"><b>&nbsp;</b></p>
    <p style={{position: 'absolute', top: 515, left: 43, whiteSpace: 'nowrap'}} className="ft25"><b>&nbsp;</b></p>
    <p style={{position: 'absolute', top: 644, left: 43, whiteSpace: 'nowrap'}} className="ft25"><b>&nbsp;</b></p>
    <p style={{position: 'absolute', top: 695, left: 43, whiteSpace: 'nowrap'}} className="ft212">&nbsp;<br />•&nbsp;</p>
    <p style={{position: 'absolute', top: 715, left: 69, whiteSpace: 'nowrap'}} className="ft26">I&nbsp;hereby&nbsp;acknowledge&nbsp;and&nbsp;declare&nbsp;that&nbsp;all&nbsp;the&nbsp;information&nbsp;provided&nbsp;in&nbsp;this&nbsp;Know&nbsp;Your&nbsp;Customer&nbsp;(KYC)&nbsp;form&nbsp;is&nbsp;true&nbsp;and&nbsp;complete&nbsp;and&nbsp;I&nbsp;</p>
    <p style={{position: 'absolute', top: 734, left: 69, whiteSpace: 'nowrap'}} className="ft26">undertake&nbsp;to&nbsp;inform&nbsp;you&nbsp;of&nbsp;any&nbsp;changes.&nbsp;</p>
    <p style={{position: 'absolute', top: 752, left: 43, whiteSpace: 'nowrap'}} className="ft26">•&nbsp;</p>
    <p style={{position: 'absolute', top: 754, left: 69, whiteSpace: 'nowrap'}} className="ft26">I&nbsp;hereby&nbsp;give&nbsp;unconditional&nbsp;and&nbsp;irrevocable&nbsp;written&nbsp;consent&nbsp;to&nbsp;by&nbsp;</p>
    <p style={{position: 'absolute', top: 754, left: 581, whiteSpace: 'nowrap'}} className="ft26">its&nbsp;subsidiaries,&nbsp;agents&nbsp;and&nbsp;authorized&nbsp;staff&nbsp;</p>
    <p style={{position: 'absolute', top: 774, left: 69, whiteSpace: 'nowrap'}} className="ft26">and&nbsp;any&nbsp;third-party&nbsp;service&nbsp;providers&nbsp;for&nbsp;disclosure,&nbsp;sharing,&nbsp;usage,&nbsp;processing&nbsp;and&nbsp;searching&nbsp;of&nbsp;my&nbsp;information&nbsp;and&nbsp;records&nbsp;as&nbsp;required&nbsp;</p>
    <p style={{position: 'absolute', top: 794, left: 69, whiteSpace: 'nowrap'}} className="ft26">by&nbsp;UAE&nbsp;Anti&nbsp;Money&nbsp;Laundering&nbsp;and&nbsp;Combatting&nbsp;of&nbsp;Terrorism&nbsp;Laws.&nbsp;&nbsp;</p>
    <p style={{position: 'absolute', top: 811, left: 43, whiteSpace: 'nowrap'}} className="ft26">•&nbsp;</p>
    <p style={{position: 'absolute', top: 814, left: 69, whiteSpace: 'nowrap'}} className="ft26">I&nbsp;agree&nbsp;that&nbsp;any&nbsp;duplication&nbsp;and&nbsp;any&nbsp;copy,&nbsp;photocopy,&nbsp;electronic&nbsp;data,&nbsp;or&nbsp;facsimile&nbsp;which&nbsp;have&nbsp;been&nbsp;made&nbsp;as&nbsp;a&nbsp;copy&nbsp;from&nbsp;this&nbsp;original&nbsp;</p>
    <p style={{position: 'absolute', top: 833, left: 69, whiteSpace: 'nowrap'}} className="ft26">consent&nbsp;by&nbsp;means&nbsp;of&nbsp;photocopying,&nbsp;image&nbsp;scanning,&nbsp;or&nbsp;recording&nbsp;in&nbsp;whatever&nbsp;forms&nbsp;shall&nbsp;be&nbsp;deemed&nbsp;as&nbsp;evidence&nbsp;of&nbsp;consent&nbsp;with&nbsp;the&nbsp;</p>
    <p style={{position: 'absolute', top: 853, left: 69, whiteSpace: 'nowrap'}} className="ft26">same&nbsp;effect&nbsp;as&nbsp;its&nbsp;original.&nbsp;</p>
    <p style={{position: 'absolute', top: 871, left: 43, whiteSpace: 'nowrap'}} className="ft26">•&nbsp;</p>
    <p style={{position: 'absolute', top: 873, left: 69, whiteSpace: 'nowrap'}} className="ft26">I&nbsp;shall&nbsp;indemnify&nbsp;and&nbsp;hold</p>
    <p style={{position: 'absolute', top: 873, left: 355, whiteSpace: 'nowrap'}} className="ft26">&nbsp;safe&nbsp;from&nbsp;any&nbsp;claims&nbsp;howsoever&nbsp;arising&nbsp;from&nbsp;as&nbsp;a&nbsp;result&nbsp;of&nbsp;such&nbsp;sharing,&nbsp;searching,&nbsp;</p>
    <p style={{position: 'absolute', top: 893, left: 69, whiteSpace: 'nowrap'}} className="ft26">usage,&nbsp;processing&nbsp;or&nbsp;disclosure&nbsp;of&nbsp;account&nbsp;information&nbsp;and&nbsp;data.&nbsp;</p>
    <p style={{position: 'absolute', top: 913, left: 69, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 932, left: 43, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 1026, left: 43, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 1046, left: 43, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 194, left: 62, whiteSpace: 'nowrap'}} className="ft27"><b>EMPLOYMENT/BUSINESS&nbsp;INFORMATION</b></p>
    <p style={{position: 'absolute', top: 194, left: 302, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 217, left: 62, whiteSpace: 'nowrap'}} className="ft26">For&nbsp;Salaried&nbsp;Individual&nbsp;</p>
    <p style={{position: 'absolute', top: 239, left: 62, whiteSpace: 'nowrap'}} className="ft26">Name&nbsp;of&nbsp;Employer&nbsp;</p>
    <p style={{position: 'absolute', top: 239, left: 221, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 262, left: 62, whiteSpace: 'nowrap'}} className="ft26">Employer&nbsp;Address&nbsp;</p>
    <p style={{position: 'absolute', top: 262, left: 221, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 285, left: 62, whiteSpace: 'nowrap'}} className="ft26">Designation&nbsp;</p>
    <p style={{position: 'absolute', top: 285, left: 221, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 308, left: 62, whiteSpace: 'nowrap'}} className="ft26">Nature&nbsp;of&nbsp;Business&nbsp;</p>
    <p style={{position: 'absolute', top: 308, left: 221, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 330, left: 62, whiteSpace: 'nowrap'}} className="ft26">Countries&nbsp;of&nbsp;Operation&nbsp;</p>
    <p style={{position: 'absolute', top: 330, left: 221, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 379, left: 62, whiteSpace: 'nowrap'}} className="ft26">For&nbsp;Business&nbsp;Owners&nbsp;</p>
    <p style={{position: 'absolute', top: 402, left: 62, whiteSpace: 'nowrap'}} className="ft26">Name&nbsp;of&nbsp;Business&nbsp;</p>
    <p style={{position: 'absolute', top: 402, left: 221, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 424, left: 62, whiteSpace: 'nowrap'}} className="ft26">Business&nbsp;Address&nbsp;</p>
    <p style={{position: 'absolute', top: 424, left: 221, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 447, left: 62, whiteSpace: 'nowrap'}} className="ft26">Years&nbsp;in&nbsp;Business&nbsp;</p>
    <p style={{position: 'absolute', top: 447, left: 221, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 447, left: 452, whiteSpace: 'nowrap'}} className="ft26">Percentage&nbsp;of&nbsp;Ownership&nbsp;</p>
    <p style={{position: 'absolute', top: 447, left: 652, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 470, left: 62, whiteSpace: 'nowrap'}} className="ft26">Nature&nbsp;of&nbsp;Business&nbsp;</p>
    <p style={{position: 'absolute', top: 470, left: 221, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 493, left: 62, whiteSpace: 'nowrap'}} className="ft26">Countries&nbsp;of&nbsp;Operation&nbsp;</p>
    <p style={{position: 'absolute', top: 493, left: 221, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 541, left: 62, whiteSpace: 'nowrap'}} className="ft27"><b>FINANCIAL&nbsp;INFORMATION</b></p>
    <p style={{position: 'absolute', top: 541, left: 219, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 593, left: 62, whiteSpace: 'nowrap'}} className="ft26">Source&nbsp;of&nbsp;Funds&nbsp;/&nbsp;Wealth&nbsp;</p>
    <p style={{position: 'absolute', top: 564, left: 241, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    

    <div style={{position: 'absolute', top: 564, left: 263, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {isSourceInDatabase("Salary")?"✓":""}
                    &nbsp;</div>
                <p  class="ft26">Salary&nbsp;</p>
            </div>
    {/* <p style={{position: 'absolute', top: 564, left: 263, whiteSpace: 'nowrap'}} className="ft26">Salary&nbsp;</p> */}
    <div style={{position: 'absolute', top: 564, left: 379, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {isSourceInDatabase("Business")?"✓":""}
                    &nbsp;</div>
                <p  class="ft26">Business&nbsp;</p>
            </div>
    {/* <p style={{position: 'absolute', top: 564, left: 379, whiteSpace: 'nowrap'}} className="ft26">&nbsp;&nbsp;Business&nbsp;</p> */}
    <div style={{position: 'absolute', top: 564, left: 481, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {isSourceInDatabase("Investment")?"✓":""}
                    &nbsp;</div>
                <p  class="ft26">Investment&nbsp;</p>
            </div>
    {/* <p style={{position: 'absolute', top: 564, left: 481, whiteSpace: 'nowrap'}} className="ft26">&nbsp;&nbsp;Investment&nbsp;</p> */}
    <div style={{position: 'absolute', top: 564, left: 600, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {isSourceInDatabase("Rental")?"✓":""}
                    &nbsp;</div>
                <p  class="ft26">Rental&nbsp;</p>
            </div>
    {/* <p style={{position: 'absolute', top: 564, left: 600, whiteSpace: 'nowrap'}} className="ft26">&nbsp;&nbsp;Rental&nbsp;</p> */}
    <div style={{position: 'absolute', top: 564, left: 698, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {isSourceInDatabase("FamilySupport")?"✓":""}
                    &nbsp;</div>
                <p  class="ft26">&nbsp;&nbsp;Family&nbsp;Support&nbsp;</p>
            </div>
    {/* <p style={{position: 'absolute', top: 564, left: 698, whiteSpace: 'nowrap'}} className="ft26">&nbsp;&nbsp;Family&nbsp;Support&nbsp;</p> */}
    <p style={{position: 'absolute', top: 587, left: 241, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <div style={{position: 'absolute', top: 587, left: 263, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {isSourceInDatabase("SaleofProperty")?"✓":""}
                    &nbsp;</div>
                <p  class="ft26">Sale&nbsp;of&nbsp;Property&nbsp;</p>
            </div>
    {/* <p style={{position: 'absolute', top: 587, left: 263, whiteSpace: 'nowrap'}} className="ft26">Sale&nbsp;of&nbsp;Property&nbsp;</p> */}
    <p style={{position: 'absolute', top: 587, left: 389, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <div style={{position: 'absolute', top: 587, left: 412, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {isSourceInDatabase("Inheritance")?"✓":""}
                    &nbsp;</div>
                <p  class="ft26">Gift&nbsp;/&nbsp;Inheritance&nbsp;</p>
            </div>
    {/* <p style={{position: 'absolute', top: 587, left: 412, whiteSpace: 'nowrap'}} className="ft26">Gift&nbsp;/&nbsp;Inheritance&nbsp;</p> */}
    <p style={{position: 'absolute', top: 587, left: 549, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <div style={{position: 'absolute', top: 587, left: 572, whiteSpace: 'nowrap'}} class="checkbox-container">
                
                <div  class="checkbox">
                {isSourceInDatabase("SaleofCompanyBusiness")?"✓":""}
                    &nbsp;</div>
                <p  class="ft26">Sale&nbsp;of&nbsp;Company&nbsp;/&nbsp;Business&nbsp;</p>
            </div>
    {/* <p style={{position: 'absolute', top: 587, left: 572, whiteSpace: 'nowrap'}} className="ft26">Sale&nbsp;of&nbsp;Company&nbsp;/&nbsp;Business&nbsp;</p> */}
    <p style={{position: 'absolute', top: 616, left: 241, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 614, left: 263, whiteSpace: 'nowrap'}} className="ft26">Other&nbsp;(please&nbsp;specify)&nbsp;{updateData.other_source_of_funds?updateData.other_source_of_funds:"……………………………………………………………………………………………………………………"}&nbsp;</p>
    <p style={{position: 'absolute', top: 672, left: 62, whiteSpace: 'nowrap'}} className="ft27"><b>DECLARATION</b></p>
    <p style={{position: 'absolute', top: 677, left: 148, whiteSpace: 'nowrap'}} className="ft28">&nbsp;</p>
    <p style={{position: 'absolute', top: 969, left: 62, whiteSpace: 'nowrap'}} className="ft26">Signature&nbsp;&nbsp;</p>
    <p style={{position: 'absolute', top: 969, left: 255, whiteSpace: 'nowrap'}} className="ft26">&nbsp;
        {updateData.signature?
        <img style={{height:'80px',width:'120px'}} src={signatureImageBaseUrl+'/'+updateData.signature} />
        :""}
        </p>
    <p style={{position: 'absolute', top: 1003, left: 62, whiteSpace: 'nowrap'}} className="ft26">Name&nbsp;</p>
    <p style={{position: 'absolute', top: 1003, left: 255, whiteSpace: 'nowrap'}} className="ft26">&nbsp;</p>
    <p style={{position: 'absolute', top: 1003, left: 538, whiteSpace: 'nowrap'}} className="ft26">Date&nbsp;</p>
    <p style={{position: 'absolute', top: 1003, left: 595, whiteSpace: 'nowrap'}} className="ft16">{updateData.date}&nbsp;</p>
    <p style={{position: 'absolute', top: 130, left: 246, whiteSpace: 'nowrap'}} className="ft210">Know Your Customer (Individual) Form</p>
    <p style={{position: 'absolute', top: 755, left: 449, whiteSpace: 'nowrap'}} className="ft211">__________________,</p>
    <p style={{position: 'absolute', top: 755, left: 458, whiteSpace: 'nowrap'}} className="ft211">K ESTATES LLC</p>
    <p style={{position: 'absolute', top: 876, left: 227, whiteSpace: 'nowrap'}} className="ft211">__________________</p>
    <p style={{position: 'absolute', top: 875, left: 243, whiteSpace: 'nowrap'}} className="ft211">K ESTATES LLC</p>
  </div>
</div>
</div>

  )
}
