import React, { useEffect, useState } from 'react'
import {
  Container,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Grid,
  Box,
  Modal,
  Alert
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import AttachmentIcon from '@mui/icons-material/Attachment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Table, Breadcrumb, Avatar, Drawer, Divider, Skeleton } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../paginationfunction";

import { Link } from 'react-router-dom';

import { BaseImageUrl } from '../../../Components/BaseImageUrl';
import UpdateContactComp from '../../../AdminComponents/UpdateContactComp';
import { Snackbar } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { BaseUrl } from '../../../Components/BaseUrl';
import { baseUrl, profileImageBaseUrl } from '../../Server/baseUrls';
import AddContactComp from '../../../AdminComponents/AddContactComp';
import AddDealComp from '../../../AdminComponents/AddDealComp';
import { format, parse } from 'date-fns';
import UpdateDealComp from '../../../AdminComponents/UpdateDealComp';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
 function AdminScreen() {
    const [deals,setDeals] = useState([])
    const [loading,setLoading] = useState(false)
    const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
    const [isUpdateClientModalOpen, setIsUpdateClientModalOpen] = useState(false);
    const [updateData, setUpdateClientData] = useState({});
    const [recordID,setRecordID] = useState("")
    const [open, setOpen] = React.useState(false);
    const [showToast, setShowToast] = React.useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [client,setClient] = useState({})
    const [profileLoading,setProfileLoading] = useState(false)
    const [agent,setAgent] = useState("")
    const [status,setStatus] = useState("")
    const [search,setSearch] = useState("")
    const [agents,setAgents] = useState([])
    


  const token = useSelector((state) => state.login.token);
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`, // Include the token as a Bearer token
    },
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowToast(false);
  };

  const showAddClientModal = () => {
    setIsAddClientModalOpen(true);
  };
  const handleAddClientOk = () => {
    setIsAddClientModalOpen(false);
  };
  const handleAddClientCancel = () => {
    setIsAddClientModalOpen(false);
  };

  const showUpdateClientModal = (record) => {
    // console.log("rec", record);
    setIsUpdateClientModalOpen(true);
    setUpdateClientData(record)
    setRecordID(record.id)
  };
  const handleUpdateClientOk = () => {
    setIsUpdateClientModalOpen(false);
  };
  const handleUpdateClientCancel = () => {
    setIsUpdateClientModalOpen(false);
  };

  const showDrawer = (record_id) => {
    console.log(record_id);
    setProfileLoading(true)
    setClient({})
    setOpenDrawer(true);

    axios.get(baseUrl + "/api/owners_details/" + record_id, axiosConfig)
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.client.documents);

          setClient(res.data.client)
          setProfileLoading(false)

        }
      })

  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    const page = 1;
    const perPage = 10;
    fetchData(page, perPage, search, status, agent);

     axios.get(baseUrl+"/api/getOwnersAndLocations",axiosConfig)
     .then((res) =>{
 
 if(res.data.success){
    setAgents(res.data.agents)
 }
   }).catch((err) =>{
      console.log("errors",err);
     })
   
    },[])


 

  const refreshPage = () => {
    setIsAddClientModalOpen(false)
    setIsUpdateClientModalOpen(false)
    const page = 1;
    const perPage = 10;

    fetchData(page, perPage, search, status, agent);

  }

  const searchClient = (e) => {
    const page = 1;
    const perPage = 10;
    const search = e.target.value
    setSearch(e.target.value)
    fetchData(page, perPage, search, status, agent);
    console.log(e.target.value);
  }

  const setStatusFilter = (e) => {
    const page = 1;
    const perPage = 10;
    const status = e.target.value
    setStatus(e.target.value)
    fetchData(page, perPage, search, status, agent);
  }

  const fetchData = async (page, perPage, searchData, status, agent) => {
    try {
      setLoading(true)
      const url = `${baseUrl}/api/deals?page=${page}&perPage=${perPage}&search=${searchData}&status=${status}&agent=${agent}`;
      const response = await axios.get(url, axiosConfig);
      setLoading(false);
      setDeals(response.data.salerentScreens);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };



    const setAgentFilter =(e) =>{
      const page = 1;
      const perPage = 10;
      const agent = e.target.value
      setAgent(e.target.value)
      fetchData(page, perPage,search,status,agent);
    }

    function formatDate(dateString) {
        const newdate = new Date(dateString);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
  
        // Formatted date string
        const formattedDate = newdate.toLocaleDateString('en-GB', options);
        return formattedDate
       
      }
    // Call fetchData function with the additional parameters
    const handleSearch = (e) => {
        // const searchValue = e.target.value;
        // fetchData(page, perPage, searchValue, status, country);
    };
    

    const currencyFormat = (num) => {

      return 'AED  ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
  

  const docs = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Document',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: '',
      render: (text, record) => (
        <div className="ms-auto">


                     
                        
            </div>
          ),
        },
      ];
      const columns = [
      
        {
          title: "Date",
          render: (text, record) => (
            <>{formatDate(record.saleDate) }</>
          )

          
        },
       
          {
            title: "Value",
            render: (text, record) => 
              <>{currencyFormat(record.dealValue)}</>

          },
        {
          title: "Project",
          dataIndex: "project"
        },
      
          {
            title: "Type",
            dataIndex: "type",
          },
          {
            title: "Area",
            dataIndex: "area",
          },
          {
            title: "Agent",
            render: (text, record) => (
               record.agents.map((agent,index)=>(
                <div key={index}>{agent.label}</div>
               ))
            )

            
          },
      
        {
          title: "Actions",
          render: (text, record) => (
            <div className="ms-auto">
         
          
              <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                              marginBottom: '1rem',
                              color: 'black',
                              borderColor: 'black',
                              '&:hover': {
                                borderColor: 'black',
                                backgroundColor: '#f5f5f5',
                              },
                            }}
                            style={{ marginBottom: '1rem' }}
                            startIcon={<ModeEditOutlineIcon />}
                            onClick={() => showUpdateClientModal(record)}
                        >
                            Update
                        </Button>
                        <Button className='ml-2'
                            variant="outlined" color="error"
                         
                            style={{ marginBottom: '1rem', marginLeft: '1rem' }}
                            onClick={() => handleDelete(record.id)}
                            startIcon={<DeleteOutlineIcon />}
                        >
                            Delete
                        </Button>

                       
                        
            </div>
          ),
        },
      ];



  
 

  const handleTableChange = (pagination) => {
    const page = pagination.current
    const perPage = pagination.pageSize
    fetchData(page, perPage, search, status, agent);
  }

  const handleDelete = (record_id) => {
    setOpen(true);
    setRecordID(record_id)


  }


  const handleClose = () => {
    setOpen(false);
  };

  const handleAgreeDelete = () => {

    axios.delete(baseUrl + "/api/deals/" + recordID, axiosConfig)
      .then((res) => {
        setOpen(false);
        if (res.data.success) {
          const page = 1
          const perPage = 10
          setTimeout(() => {
            fetchData(page, perPage, search, status, agent);
            setShowToast(true)
           }, 200);
          
          
            }
        }).catch((err) =>{
          console.log("err",err);
        })
      }

  return (
    <Container maxWidth={'xl'} >
      <Drawer width={"50%"} title="Client Details" onClose={onCloseDrawer} open={openDrawer} style={{ marginTop: '20px' }}>
        <Skeleton loading={profileLoading} active avatar >


          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '20px' }}>
            <Avatar size={{
              xs: 24,
              sm: 32,
              md: 40,
              lg: 64,
              xl: 80,
              xxl: 80,
            // }} src={BaseImageUrl + "profile_images/" + client.profile_image} />
          }} src={ profileImageBaseUrl +"/"+client.profile_image} />
            <div>
              <p>Name    {client.name}</p>
              <p>Email    {client.email}</p>
              <p>Phone    {client.phone}</p>
              <p>Status    {client.active}</p>
            </div>
          </div>


        </Skeleton>

        <Divider />
        <Skeleton loading={profileLoading} active >
          <Table dataSource={client.documents} columns={docs} pagination={false} />
        </Skeleton>
      </Drawer>
      <Snackbar
        open={showToast}
        onClose={handleCloseToast}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
          Deals Successfully deleted
        </Alert>
      </Snackbar>


      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You wont able revert
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>Close</Button>
          <Button variant="outlined" onClick={handleAgreeDelete}  startIcon={<CheckIcon />}>
           Continue 
          </Button>
        </DialogActions>
      </Dialog> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Delete Deal
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure to delete? You will not be able back
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>Close</Button>
          <Button variant="outlined" sx={{
       
        color: 'black',
        borderColor: 'black',
        '&:hover': {
          borderColor: 'black',
          backgroundColor: '#f5f5f5',
        },
      }} onClick={handleAgreeDelete} startIcon={<CheckIcon />}>
            Continue
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Modal
        open={isAddClientModalOpen}
        onClose={handleAddClientOk}
        aria-labelledby="add-client-modal-title"
        aria-describedby="add-client-modal-description"
      >
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%', // Adjust width as needed for responsiveness
            maxWidth: 900, // Increase maxWidth for larger screens
            height: '80%', // Adjust height as needed for responsiveness
            maxHeight: 1200, // Set maxHeight to 90vh for responsiveness
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            // maxHeight: '80vh', // Set maxHeight to 80vh for responsiveness
            // overflowY: 'auto', // Enable vertical scrolling
            // overflowX: 'hidden', // Disable horizontal scrolling
            // backgroundColor: 'white'
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Add New Deal
          </Typography>
          <Divider />
          <AddDealComp xCLoseModal={() => refreshPage()}  agents={agents} />
        </Box>
      </Modal>

      <Modal
        open={isUpdateClientModalOpen}
        onClose={handleUpdateClientOk}
        aria-labelledby="add-client-modal-title"
        aria-describedby="add-client-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%', // Adjust width as needed for responsiveness
          maxWidth: 900, // Increase maxWidth for larger screens
          height: '80%', // Adjust height as needed for responsiveness
          maxHeight: 1200, // Set maxHeight to 90vh for responsiveness
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          //maxHeight: '80vh', // Set maxHeight to 80vh for responsiveness
        
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Update Deal
          </Typography>
          <Divider />
          <UpdateDealComp  id={recordID} xCLoseModal={() => refreshPage()}  agents={agents} updateData={updateData} />

        </Box>
      </Modal>
      {/* <Modal width={1000} title="Add New Client" 
        open={isAddClientModalOpen} onOk={handleAddClientOk}
         onCancel={handleAddClientCancel}
        
         >
             <AddClientComp />
      
      </Modal> */}
      <div className=''>
        <Grid container spacing={2} display={'flex'} alignItems={'center'} marginBottom={'1rem'} >

          <Grid item xs={12} sm={12} md={6} xl={6} lg={6} >
            <Typography variant='h3' align='left' width={'100%'} marginBottom={'1rem'}>
            Deals
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} xl={6} lg={6} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={showAddClientModal}
              style={{ marginBottom: '1rem', backgroundColor: "black" }}
            >
              Add New Deal
            </Button>
          </Grid>

        </Grid>


        <Grid container spacing={2} display={'flex'} alignItems={'center'} marginBottom={'1rem'} >

          <Grid item xs={12} sm={12} md={8} xl={8} lg={8} >
            <Box
              sx={{
                width: '100%',
                // maxWidth: '100%',
              }}
            >
              <TextField
                label="Search"
                variant="outlined"
                // value={searchTerm}
                onChange={(e) => searchClient(e)}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} xl={4} lg={4}  >
                       
                        <Select
                                // value={statusFilter}
                                onChange={(e) => setAgentFilter(e)}
                                variant="outlined"
                                fullWidth
                                displayEmpty
                            >
                               <MenuItem value="">All Agents</MenuItem>
                              {agents.map((agent,index) =>(
                            <MenuItem key={index} value={agent.value}>{agent.label}</MenuItem>
                              ))}
                               
                               
                            </Select>
                        
                       
                    </Grid>

        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6} lg={6} >
          <Table
            onChange={handleTableChange}
            loading={loading}
            className="table-striped"
            pagination={{
              pageSizeOptions: ["10", "20", "50", "100"],
              total:
              deals?.total > 0 &&
              deals.total,
              pageSize: deals.per_page,
              current: deals.current_page,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              itemRender: itemRender,
            }}
            style={{ overflowX: "auto" }}
            columns={columns}
            // bordered
            dataSource={
                deals?.data
                ? deals?.data
                : []
            }
          //   rowKey={(record) => record}
          />
        </Grid>

      </div>

    </Container>
  )
}

export default AdminScreen
