import React, { useEffect, useState } from 'react';
import { 
    Button,
    Input,
    Typography,
    Avatar,
    Grid,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    FormHelperText

} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
// Local
import "./AddClientComp.css";
import { user } from '../LocalDB';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BaseUrl } from '../Components/BaseUrl';
import { baseUrl } from '../Pages/Server/baseUrls';
import CurrencyInput from 'react-currency-input-field';
import {DatePicker} from 'antd'

const UpdateNewListingComp = ({id,xCLoseModal,developers,updateData}) => {
   
    const [name, setName] = useState('');
    const [starting_price, setStartingPrice] = useState('');
    const [developer_id,setDeveloperID] = useState('')
    const [payment_plan, setPaymentPlan] = useState('');


    // const [countries,setCountries] = useState([])
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});

    const token = useSelector((state) => state.login.token);

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
            'Content-Type': 'multipart/form-data'
        },
    };

    useEffect(() =>{
        setName(updateData.project)
        setDeveloperID(updateData.developer_id)
        setStartingPrice(updateData.starting_price)
        setPaymentPlan(updateData.payment_plan)

},[updateData])
  
    const handleNameChange = (event) => {
      setName(event.target.value);
    };

 
  




    const validate = () => {
        let input = inputs;
        let errors = {};
        let isValid = true;
        if (!name) {
          isValid = false;
          errors["name"] = "Project is required";
        }

        if (!developer_id) {
            isValid = false;
            errors["developer_id"] = "Developer is required";
          } 

          if (!starting_price) {
            isValid = false;
            errors["starting_price"] = "Starting price is required";
          }

          if (!payment_plan) {
            isValid = false;
            errors["payment_plan"] = "Payment Plan is required";
          }


        
       

        setErrors(errors)
        return isValid;
      };
  
    const handleUpdateNewListing = () => {

   
    if(validate()){
      const formData = new FormData();
      formData.append('id',id)
      formData.append('project', name);
      formData.append('starting_price', starting_price);
      formData.append('payment_plan', payment_plan);
      formData.append('developer_id', developer_id);
    
     

    

      axios.post(baseUrl+"/api/updatelaunch", formData, {
          headers: {
              'Content-Type': 'multipart/form-data', // Important: set content type to multipart/form-data
              ...axiosConfig.headers // Include any other headers
          }
      })
      .then((res) => {
        xCLoseModal()
          console.log("res server", res.data);
      })
      .catch((error) => {
        xCLoseModal()
          console.error("Error:", error);
      });
     
    }else{
        return
    }
}


  
    return(
        <div style={{ width:"100%", display:'flex', alignItems:"center" , justifyContent:"center"}} >
            <div className='addClient-container' >
                {/* <h1> User Acount </h1> */}
                {/* <Typography variant="h5" style={{ fontFamily:'Montserrat'}} >{user.name}</Typography>
                <Typography variant="caption" style={{ fontFamily:'Montserrat'}} >{user.address}</Typography> */}

            
                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Project"
                            value={name}
                            onChange={handleNameChange}
                        />
                         <div className="text-danger">
                          {errors.name}
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl fullWidth>
    <InputLabel>Developer</InputLabel>
    <Select
        value={developer_id}
        onChange={(e) => setDeveloperID(e.target.value)}
    >
        {
            developers.map((e, index) => {
                return (
                    <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
                )
            })
        }
    </Select>
    <FormHelperText className='text-danger'>{errors.developer_id}</FormHelperText>

</FormControl>
                       
                    </Grid>

               
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                    <CurrencyInput
                            style={{
                                width: '100%', padding: '15px', boxSizing: 'border-box', border: '1px solid #ccc', borderRadius: '4px',
                                fontSize: '16px', transition: 'border-color 0.3s'
                            }}
                            id="input-example"
                            name="input-name"
                            label="Starting price"
                            placeholder="Starting price"
                            //   defaultValue={0}
                            value={starting_price}
                            decimalsLimit={2}
                            onValueChange={(value, name, values) => setStartingPrice(value)}
                            error={!!errors.starting_price}
                            helperText={errors.starting_price}
                        />
                           <div className="text-danger">
                          {errors.starting_price}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="Payment plan"
                                
                                value={payment_plan}
                                onChange={(e) =>setPaymentPlan(e.target.value)}
                            />
                              <div className="text-danger">
                          {errors.date}
                        </div>
                    </Grid>

            
                </Grid> 


               
     

                <Grid item xs={12} style={{ marginTop: '2rem' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateNewListing}
                    >
                        Update listing
                    </Button>
                </Grid>
            </div>
        </div>
    )
}

export default UpdateNewListingComp;