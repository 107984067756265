import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import kestateImage from "../../Images/kestate.jpg"
import kestateImage from "../../Images/LoginImage.png"
// Local 
import logo from '../../Images/2.png'; // Import the image
import Fade from '@mui/material/Fade';
import LogoImage from '../../Images/logo.png'; // Import your logo image
import { GET_API_LOGIN } from '../Server/Apis';
import { httpRequest } from '../Server/Axios';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, setToken } from './sliceLogin';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import "./login.css";
import BeatLoader from "react-spinners/BeatLoader";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://localhost:3000">
        kestate.ae
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.login.user);
  const tokenInfo = useSelector((state) => state.login.token);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const renderLoginButton = () => {

    return <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2, height: 50 }}
      // sx={{ mt: 3, mb: 2 }}
      style={{ backgroundColor: 'black' }}
    >
      {isLoading ? <BeatLoader color="white"
        // loading={loading}

        size={5}
      /> :
        "Sign In"}
    </Button>

  }

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });

    const API_LOGIN_CONFIG = GET_API_LOGIN(email, password);

    try {
      const response = await httpRequest(API_LOGIN_CONFIG);
      if (response.success) {
        setIsLoading(false)
        dispatch(loginSuccess(response.data));
        dispatch(setToken(response.data));
        navigate('/')
      }
    } catch (error) {
      setIsLoading(false)
      setOpen(true)

    }

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        // onClose={handleClose}
        message="Wrong credential"
      />
      <Grid container component="main" sx={{ height: '100vh' }} bgcolor={'black'} >
        {/* <CssBaseline /> */}
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          // sx={{
          //   backgroundImage: `url(${kestateImage})`, // Set the imported image as background
          //   backgroundRepeat: 'no-repeat',
          //   backgroundColor: (t) =>
          //     t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          //   backgroundSize: 'cover',
          //   backgroundPosition: 'center',
          // }}


          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <img src={logo} className='image_style' />
        </Grid>
        <Grid item xs={12} sm={8} md={5} elevation={6} square style={{ display: 'flex', alignItems: "center", justifyContent: "center" }} >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor:"white",
              padding:'2rem'
            }}
          >
            {/* <img src={LogoImage} style={{ width: "100%", height: '15rem' }} /> */}
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <Box component="form" noValidate onSubmit={handleLogin} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              {renderLoginButton()}

              <Grid container>
                <Grid item xs>
                  <Link href="/Forgot_Password" variant="body2" style={{ color: 'black' }}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}