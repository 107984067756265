import React, { useEffect, useState } from 'react';
import {
    Grid,
    Button,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    Container,
    Avatar,
    FormHelperText
} from '@mui/material';
import ImageCompressor from 'image-compressor.js';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import './AddPropertyComp.css';
import ImageUploading from 'react-images-uploading';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useLocation } from 'react-router-dom';
import { AmountSaperator } from '../Utilities';
import axios from 'axios';
import { BaseUrl } from '../Components/BaseUrl';
import { useSelector } from 'react-redux';
import { BaseImageUrl } from '../Components/BaseImageUrl';
import { Image, Modal ,Divider} from 'antd';
import { baseUrl, profileImageBaseUrl, propertyImagesUrl,iconsUrl } from '../Pages/Server/baseUrls';
import BeatLoader from "react-spinners/BeatLoader";
import SaveIcon from '@mui/icons-material/Save';
import CurrencyInput from 'react-currency-input-field';
import $ from "jquery";
import { Helmet } from 'react-helmet';

const UpdatePropertyComp = ({id,owner,locations,developers,property_types,xCLoseModal, xImages,updateproperty,onRemoveImage,amenities = [],contacts }) => {

    
    const { state } = useLocation();
    const [propertyImages, setPropertyImages] = useState([]);
    const [propertyName, setPropertyName] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [bathrooms, setBathrooms] = useState('');
    const [totalArea, setTotalArea] = useState('');
    const [location, setLocation] = useState('');
    const [developer,setDeveloper] = useState('')
    const [listingType, setListingType] = useState('rent');
    const [propertyType, setPropertyType] = useState('sale');
    const [expectedPrice, setExpectedPrice] = useState('');
    const [showimages, setShowImages] = useState([]);
    const [images, setImages] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    // const [showSelectedUsers, setShowSelectedUsers] = useState([]);
    const [selectedData,setSelectdData] = useState([])
    const [owners,setOwners] = useState([])
    const [property,setProperty] = useState({})
    const [errors, setErrors] = useState({});
   const [loading,setLoading] = useState(false);
   const [unitno, setUnitno] = useState('');

   const [isModalOpen, setIsModalOpen] = useState(false)
   const [ lat,setLat] = useState(25.1972018)
   const [ lng,setLng] = useState(55.2721877)
   const [isDocumentsModalOpen,setIsDocumentsModalOpen] = useState(false)
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [floorplan,setFloorplan] = useState(null)
    const [masterplan,setMasterplan] = useState(null)
    const [paymentplan,setPaymentplan] = useState(null)
    const [brochure,setBrochure] = useState(null)
    const [video,setVideo] = useState(null)
    const [factSheet,setFactSheet] = useState(null)
    const [renders,setRenders] = useState(null)
    const [contact, setContact] = useState('')

    const [showpaymentplan,setShowPaymentplan] = useState(null)
    const [showbrochure,setShowBrochure] = useState(null)
    const [showfloorplan,setShowFloorplan] = useState(null)
    const [showvideo,setShowVideo] = useState(null)
    const [showfactSheet,setShowFactSheet] = useState(null)
    const [showrenders,setShowRenders] = useState(null)


    const token = useSelector((state) => state.login.token);
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
        },
    };
    let map;

    useEffect(() => {
        if (updateproperty && updateproperty != null) {
            setShowBrochure(null);
            setShowFactSheet(null);

            setShowPaymentplan(null);
            setShowFloorplan(null);
            setShowRenders(null);
            setShowVideo(null);
           
            if(updateproperty.documents && updateproperty.documents.length> 0){
                updateproperty.documents.forEach(doc => {
                    switch (doc.document_id) {
                      case 1:
                        setShowBrochure(doc.url);
                        break;
                      case 2:
                        setShowFactSheet(doc.url);
                        break;
                      case 3:
                        setShowPaymentplan(doc.url);
                        break;
                      case 4:
                        setShowFloorplan(doc.url);
                        break;
                      case 5:
                        setShowRenders(doc.url);
                        break;
                      case 6:
                        setShowVideo(doc.url);
                        break;
                      default:
                        break;
                    }
                  });
            }else{
                setShowBrochure(null);
                setShowFactSheet(null);

                setShowPaymentplan(null);
                setShowFloorplan(null);
                setShowRenders(null);
                setShowVideo(null);
            }
       
           
      
        setPropertyName(updateproperty.title ? updateproperty.title : '' );
        setBedrooms(updateproperty.bedrooms ? updateproperty.bedrooms : '' )
        setBathrooms(updateproperty.bathrooms ? updateproperty.bathrooms : '' )
        setTotalArea(updateproperty.area_size ? updateproperty.area_size : '' )
        setUnitno(updateproperty.unit_no ? updateproperty.unit_no : '' )
        setListingType(updateproperty.status ? updateproperty.status : '' )
        setPropertyType(updateproperty.property_type_id ? updateproperty.property_type_id : '')
        setExpectedPrice(updateproperty.price ? updateproperty.price : '' )
        setLocation(updateproperty.location_id?updateproperty.location_id:"")
        setDeveloper(updateproperty.developer_id?updateproperty.developer_id:"")
        setPropertyImages(updateproperty.images && updateproperty.images.length > 0?updateproperty.images:[])
   
        setLat(updateproperty.lat)
        setLng(updateproperty.lng)
        setFloorplan(updateproperty.floorplan?updateproperty.floorplan:null)
        setMasterplan(updateproperty.masterplan?updateproperty.masterplan:null)
        setPaymentplan(updateproperty.paymentplan?updateproperty.paymentplan:null)
        setBrochure(updateproperty.brochure?updateproperty.brochure:null)
        setContact(updateproperty.contact_id?updateproperty.contact_id:'')
        const selectedIds = updateproperty.amenities.map(item => item.amenities_id);
        setSelectedAmenities(selectedIds);
        setSelectedAmenities(selectedIds?selectedIds:[])
        setTimeout(() => {
            initMap()
        }, 1000);
      
  
        setImages([]);
              setShowImages([])

        //   if (updateproperty.owners && updateproperty.owners.length > 0) {
        //     setSelectedUsers(updateproperty.owners);
        //   } else {
        //     setSelectedUsers([]);
        //   }

          
        } else {
          console.log("no proper");
        }
      }, [updateproperty]);




   const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDocumentModal = () =>{
    setIsDocumentsModalOpen(true);
  }

  const handleDocumentsOk = () => {
    setIsDocumentsModalOpen(false);
  };
  const handleDocumentsCancel = () => {
    setIsDocumentsModalOpen(false);
  };

 
    const handleUserChange = (event) => {
      setSelectedUsers(event.target.value);
    };
    
    const compressImages = async () => {
        const compressedImages = [];

        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            const options = {
                maxWidth: 800,
                maxHeight: 600,
                quality: 0.7, // Adjust compression quality as needed (0.7 means 70%)
                success(result) {
                    compressedImages.push(result);
                },
                error(e) {
                    console.error(e.message);
                },
            };

            await new ImageCompressor(file, options).compress();
        }
    }

    const maxNumber = 69;
  
    const onChange = (imageList, addUpdateIndex) => {
        const newimages = imageList.map(option => option.file);
       
      setShowImages(imageList);
      setImages(newimages)
    };
    

    // useEffect(() => {
    //     // Map the URLs to objects with 'data_url' key
    //     const imageURLs = PropertyDetails.xSlideImagesArr.map((e) => ({
    //       data_url: e.url
    //     }));
    //     // setImages(imageURLs);
    //     // setPropertyName(PropertyDetails.xPropertyName);
    //     // setBedrooms(PropertyDetails.xNoOfBeds)
    //     // setBathrooms(PropertyDetails.xNoOfBath)
    //     // setTotalArea(PropertyDetails.xArea)
    //     // setListingType(PropertyDetails.status)
    //     // setPropertyType({ name:PropertyDetails.type, value: undefined })
    //     // setExpectedPrice(PropertyDetails.xPrice)
    //     // setPropertyType(PropertyDetails.type);
    //   }, [PropertyDetails]); // Make sure to include PropertyDetails in the dependency array
    
  
    const handleImageUpload = (event) => {
      const files = Array.from(event.target.files);
      setPropertyImages(files);
    };
  
    const handleFormSubmit = () => {
      // Implement form submission logic here
      console.log('Form submitted:', {
        propertyImages,
        propertyName,
        bedrooms,
        bathrooms,
        totalArea,
        location,
        listingType,
        propertyType,
        expectedPrice,
      });
    };

    const handleLocation = (e) =>{
        setLocation(e.target.value)
        const selectedLocation = locations.find(loc => loc.value === e.target.value);
        // $("#location_selected").val(selectedLocation.label);
    
        let service;
        let infowindow;
        const mapOptions = {
            center: new window.google.maps.LatLng(lat, lng),
            zoom: 15,
            mapTypeId: window.google.maps.MapTypeId.HYBRID,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            scrollwheel: false,
            draggable: true,
            gestureHandling: "cooperative",
          };
      
           map = new window.google.maps.Map(document.getElementById("google-map"), mapOptions);

              const request = {
                query: selectedLocation.label ,
                fields: ["name", "geometry"],
              };
            
          
               infowindow = new window.google.maps.InfoWindow();
               service = new window.google.maps.places.PlacesService(map);
          
              service.findPlaceFromQuery(request, (results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                  createMarker(results[0], map);
                  map.setCenter(results[0].geometry.location);
                  setLat(results[0].geometry.location.lat());
                  setLng(results[0].geometry.location.lng());
                  $("#lat").val(results[0].geometry.location.lat());
                  $("#lng").val(results[0].geometry.location.lng());
                }
              });
        // setTimeout(() => {
        //     initMap()
        // }, 1000);
        
    }
    // const handleCheckboxChange = (event, amenity) => {
    //     if (event.target.checked) {
    //         // Add amenity to the selected amenities array
    //         setSelectedAmenities([...selectedAmenities, amenity]);
    //     } else {
    //         // Remove amenity from the selected amenities array
    //         setSelectedAmenities(selectedAmenities.filter(a => a.name !== amenity.name));
    //     }
    // };
    const handleCheckboxChange = (event, amenityId) => {
        if (event.target.checked) {
            setSelectedAmenities(prevSelectedAmenities => [...prevSelectedAmenities, amenityId]);
        } else {
            setSelectedAmenities(prevSelectedAmenities => prevSelectedAmenities.filter(id => id !== amenityId));
        }
    };
    
    const renderMap = () => {
        window.initMap = initMap;
      };
      const initMap = () => {
        let service;
        let infowindow;

        const mapOptions = {
            center: new window.google.maps.LatLng(lat, lng),
            zoom: 15,
            mapTypeId: window.google.maps.MapTypeId.HYBRID,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            scrollwheel: false,
            draggable: true,
            gestureHandling: "cooperative",
          };
      
           map = new window.google.maps.Map(document.getElementById("google-map"), mapOptions);

    

        //    if(loc && loc !=""){
        //     console.log("new loc");
        //     const request = {
        //         query: loc ,
        //         fields: ["name", "geometry"],
        //       };
          
        //        infowindow = new window.google.maps.InfoWindow();
        //        service = new window.google.maps.places.PlacesService(map);
          
        //       service.findPlaceFromQuery(request, (results, status) => {
        //         if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
        //           createMarker(results[0], map);
        //           map.setCenter(results[0].geometry.location);
        //           setLat(results[0].geometry.location.lat());
        //           setLng(results[0].geometry.location.lng());
        //           $("#lat").val(results[0].geometry.location.lat());
        //           $("#lng").val(results[0].geometry.location.lng());
        //         }
        //       });
        //    }else

           if (updateproperty.lat !=null && updateproperty.lng !=null && updateproperty.lat != "25.1972018" && updateproperty.lng != "55.2721877") {
           
         
            createMarker({ geometry: { location: new window.google.maps.LatLng(updateproperty.lat, updateproperty.lng) } }, map);
            map.setCenter(new window.google.maps.LatLng(updateproperty.lat, updateproperty.lng));
            return; // Exit if lat and lng are provided directly
          }else
          {
            
            const request = {
              query:  "Burj khalifa",
              fields: ["name", "geometry"],
            };
        
             infowindow = new window.google.maps.InfoWindow();
             service = new window.google.maps.places.PlacesService(map);
        
            service.findPlaceFromQuery(request, (results, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                createMarker(results[0], map);
                map.setCenter(results[0].geometry.location);
                setLat(results[0].geometry.location.lat());
                setLng(results[0].geometry.location.lng());
                $("#lat").val(results[0].geometry.location.lat());
                $("#lng").val(results[0].geometry.location.lng());
              }
            });
          }
      
   
    
      };
      const createMarker =(place) => {
        if (!place.geometry || !place.geometry.location) return;
    
        const marker = new window.google.maps.Marker({
          map,
          position: place.geometry.location,
          draggable: true,
        });
    
        window.google.maps.event.addListener(marker, "dragend", function (event) {
          $("#lat").val(this.getPosition().lat());
          $("#lng").val(this.getPosition().lng());
          setLat(this.getPosition().lat())
          setLng(this.getPosition().lng())
        });
    
        // map.addListener("click", (e) => {
        //   $("#lat").val(e.latLng.lat());
        //   $("#lng").val(e.latLng.lng());
        //   setLat(e.latLng.lat())
        //   setLng(e.latLng.lng())
        //   map.setCenter(marker.setPosition(e.latLng));
        // });
      }
    
    const dubaiAreas = [
        { value:'dubai_marina', label:'Dubai Marina' },
        { value:'dubai_hills', label:'Dubai Hills' },
        { value:'albarsha_1', label:'Al Barsha 1' },
        { value:'albarsha_2', label:'Al Barsha 2' },
        { value:'albarsha_3', label:'Al Barsha 3' },
        { value:'jbr', label:'Jumeirah Beach' },
    ]

     // Sample data
    const users = [
        { id: 1, name: 'User 1' },
        { id: 2, name: 'User 2' },
        { id: 3, name: 'User 3' },
        // Add more users as needed
    ];

    const handleAutocompleteChange= (event, value) =>{
        // console.log("**** Value **** ", value)
        const ids = value.map(option => option);
        setSelectedUsers(ids);
        // setShowSelectedUsers(value)
    }

    const validateForm = () => {
        const newErrors = {};
        if (!propertyName) newErrors.propertyName = 'Property name is required';
        if (!bedrooms) newErrors.bedrooms = 'Number of bedrooms is required';
        if (!bathrooms) newErrors.bathrooms = 'Number of bathrooms is required';
        if (!totalArea) newErrors.totalArea = 'Total area is required';
        if (!location) newErrors.location = 'Location is required';
        if (!developer) newErrors.developer = 'Developer is required';
        if (!expectedPrice) newErrors.expectedPrice = 'Expected price is required';
        if (!propertyType) newErrors.propertyType = 'Property type is required';
        if (!listingType) newErrors.listingType = 'Listing type is required';
        if (!listingType) newErrors.selectedUsers = 'User is required';
        if (!unitno) newErrors.unitno = 'Unitno  is required';
        if (!contact) newErrors.contact = 'Owner is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

   const ImageUpdate = (img) =>{
    console.log("d",img);
   }

   const ImageRemove =(img) =>{
   
    const data = {property_id:img.property_id,image_id:img.id}
       axios.post(baseUrl+"/api/deletepropertyImage",data,axiosConfig)
       .then((res) =>{
        if(res.data.success){
            const images = propertyImages.filter(image => image.id !== img.id);
            setPropertyImages(images)
            onRemoveImage(images);
            
        }
        console.log("re",res.data);
       }).catch((err) =>{
        console.log("err",err);
       })
   }
    const handleSubmit = async () => {
       

      
        if (!validateForm()) return;
    
        setLoading(true);
    
        const formData = new FormData();
        // images.forEach((file, index) => {
        //     formData.append(`images[${index}]`, file);
        // });

     
    
        selectedUsers.forEach((file, index) => {
            formData.append(`owners[${index}]`, file.id);
          });

          selectedAmenities.forEach((file, index) => {
            formData.append(`amenities[${index}]`, file);
        });

        formData.append("id",id)
        formData.append("title",propertyName)
        formData.append("bedrooms",bedrooms)
        formData.append("bathrooms",bathrooms)
        formData.append("area_size",totalArea)
        formData.append("location_id",location)
        formData.append("property_type_id",propertyType)
        formData.append("status",listingType)
        formData.append("price",expectedPrice)
        formData.append("developer_id",developer)
        formData.append("unit_no",unitno)
        formData.append("lat", lat)
        formData.append("lng", lng)
        formData.append("contact_id", contact)
        const fileTypes = [];

        if (brochure) {
            formData.append('files[]', brochure);
            fileTypes.push('brochure');
          }
          if (factSheet) {
            formData.append('files[]', factSheet);
            fileTypes.push('factSheet');
          }
          if (paymentplan) {
            formData.append('files[]', paymentplan);
            fileTypes.push('paymentplan');
          }
        if (floorplan) {
          formData.append('files[]', floorplan);
          fileTypes.push('floorplan');
        }
       
        if (renders) {
          formData.append('files[]', renders);
          fileTypes.push('renders');
        }
        if (video) {
          formData.append('files[]', video);
          fileTypes.push('video');
        }
        
        formData.append('file_types', JSON.stringify(fileTypes));


            // const formData = new FormData();
            // formData.append('images', images);
            // xCLoseModal()
        axios.post(baseUrl+"/api/update-properties",formData,axiosConfig)
        .then((res) =>{
            if(res.data.success){
            setLoading(false)
            xCLoseModal()
            let hasFiles = false;
            const imagesFormData = new FormData();
            images.forEach((file, index) => {
                if(file && file != undefined){
                    imagesFormData.append(`images[${index}]`, file);
                    hasFiles = true;
                }
            });

           
            imagesFormData.append("property_id", res.data.property_id);
            if (hasFiles) {
            axios.post(baseUrl + "/api/upload-files", imagesFormData, axiosConfig)
            .then((res) =>{
                setImages([])
                setShowImages([])
                xCLoseModal()
                console.log("images upload",res.data);
            }).catch((err) =>{
                console.log("err:",err);
            })
        }else{
            console.log("no images");

        }
            }
            
             else {
                // Handle response with success: false
                setLoading(false);
                console.log("Server response was not successful:", res.data);
            }
        }).catch((err) =>{
            setLoading(false)
            console.log("err",err);
        });
    
    }
    
  const handleFloorplan = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.floorplan = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setFloorplan(null)
        return 
    }else{
        setFloorplan(file)
    }
 }

 const handleVideo = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.video = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setVideo(null)
        return 
    }else{
        setVideo(file)
    }
 }

 const handleFactSheet = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.factSheet = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setFactSheet(null)
        return 
    }else{
        setFactSheet(file)
    }
 }

 const handleRenders = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.renders = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setRenders(null)
        return 
    }else{
        setRenders(file)
    }
 }

 const handleMasterplan = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.masterplan = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setMasterplan(null)
        return 
    }else{
        setMasterplan(file)
    }
 }

 const handlePaymentplan = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.paymentplan = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setPaymentplan(null)
        return 
    }else{
        setPaymentplan(file)
    }
 }
 const handleBrochure = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.brochure = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setBrochure(null)
        return 
    }else{
        setBrochure(file)
    }
 }

     const handleSelectAllChange = (event) => {
        if (event.target.checked) {
          const allAmenityIds = amenities.map(amn => amn.id);
          setSelectedAmenities(allAmenityIds);
        } else {
          setSelectedAmenities([]);
        }
      };
    
      const areAllSelected = amenities.length > 0 && selectedAmenities.length === amenities.length;
    
    return(
        // <Container maxWidth={'xl'} >
            <div>
                    <Modal width={1000} title="Documents" footer={null} open={isDocumentsModalOpen} onOk={handleDocumentsOk} onCancel={handleDocumentsCancel}>
       <Divider />
       <table class="table table-bordered">
 
  <tbody>
    <tr>
      <th>Brochure</th>
      <td>
        <span>{showbrochure}</span>
         <input
            type="file"
            required
            onChange={handleBrochure}
            name="number_of_installment"
            className="form-control"
          />
          </td>
    </tr>
    <tr>
      <th>Fact Sheet</th>
      <td>
      <span>{showfactSheet}</span>
        <input
            type="file"
            required
            onChange={handleFactSheet}
            name="number_of_installment"
            className="form-control"
          /></td>
    </tr>
    <tr>
      <th>Payment Plan</th>
      <td>
      <span>{showpaymentplan}</span>
        <input
            type="file"
            required
            onChange={handlePaymentplan}
            name="number_of_installment"
            className="form-control"
          /></td>
    </tr>
    <tr>
      <th>Floor Plans</th>
      <td>
      <span>{showfloorplan}</span><input
            type="file"
            required
            onChange={handleFloorplan}
            name="number_of_installment"
            className="form-control"
          /></td>
    </tr>
    <tr>
      <th>Renders</th>
      <td>
      <span>{showrenders}</span>
        <input
            type="file"
            required
            onChange={handleRenders}
            name="number_of_installment"
            className="form-control"
          /></td>
    </tr>

    <tr>
      <th>Videos</th>
      <td>
      <span>{showvideo}</span>
        <input
            type="file"
            required
            onChange={handleVideo}
            name="number_of_installment"
            className="form-control"
          /></td>
    </tr>
 
  </tbody>
</table>
      </Modal>
                        <Modal width={800} title="Amenities" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
       <Divider />
       <FormControlLabel
        control={
          <Checkbox
            checked={areAllSelected}
            onChange={handleSelectAllChange}
            color="primary"
          />
        }
        label="Select All"
      />
      <div className='row'>
        {Array.isArray(amenities) && amenities.map((amn,index)=>(
            <FormControlLabel
            key={index}
            className='col-md-3'
            label={
                <div style={{ display: 'flex',justifyContent:'space-between', alignItems: 'center' }}>
                    {amn.icon?
                    <img src={iconsUrl+"/"+amn.icon } style={{height:'30px',width:'30px'}}  />:
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-wheelchair" viewBox="0 0 16 16">
  <path d="M12 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m-.663 2.146a1.5 1.5 0 0 0-.47-2.115l-2.5-1.508a1.5 1.5 0 0 0-1.676.086l-2.329 1.75a.866.866 0 0 0 1.051 1.375L7.361 3.37l.922.71-2.038 2.445A4.73 4.73 0 0 0 2.628 7.67l1.064 1.065a3.25 3.25 0 0 1 4.574 4.574l1.064 1.063a4.73 4.73 0 0 0 1.09-3.998l1.043-.292-.187 2.991a.872.872 0 1 0 1.741.098l.206-4.121A1 1 0 0 0 12.224 8h-2.79zM3.023 9.48a3.25 3.25 0 0 0 4.496 4.496l1.077 1.077a4.75 4.75 0 0 1-6.65-6.65z"/>
</svg>}
                    {/* {amn.icon} */}
                    <span style={{ marginLeft: 8 }}>{amn.name}</span>
                </div>
            }
            control={<Checkbox    
                // checked={selectedAmenities.some(a => a.name === amn.name)
                checked={selectedAmenities.includes(amn.id)}

            
            onChange={(e) => handleCheckboxChange(e, amn.id)} />}
           
        />
        ))}
        </div>
      </Modal>
                <div className='' >
                    <div className="">
                        <ImageUploading
                            multiple
                            value={showimages}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (
                            // write your building UI
                            <div className="upload__image-wrapper" >
                                <Button variant="contained" color="primary" type="submit" size='small' onClick={onImageUpload} style={{ marginLeft: '0rem',backgroundColor: "black" }} >
                                    Click or Drop here
                                </Button>
                                <Button variant="contained" color="primary" type="submit" size='small' onClick={onImageRemoveAll} style={{ marginLeft: '1rem',backgroundColor: "black" }} >
                                    Remove all images
                                </Button>

                                
                                <Grid container spacing={1} style={{ marginTop: '1rem', width:'100%' }} className='images-list' >
                                    {
                                        imageList.slice(0,10).map((image, index) => {
                                            return(
                                                <Grid key={index} item xs={6} sm={6} md={4} lg={4} xl={3.5} margin={1} >
                                                    <div className='image-controller' >
                                                        {/* <ChangeCircleIcon onClick={() => onImageUpdate(index)} color='info' /> */}
                                                        <RemoveCircleIcon onClick={() => onImageRemove(index)} color='error' />
                                                    </div>
                                                    {
                                                        image['data_url']?
                                                        <img src={image['data_url']} alt="" style={{ width:'280px', height:'180px' }} />
                                                        :
                                                        <img src={propertyImagesUrl+"/"+image.name} alt="" style={{ width:'280px', height:'180px' }} />
                                                    }
                                                </Grid>
                                            )
                                        })}
                                            {propertyImages?.map((img,index) =>(
                                                <Grid key={index} item xs={6} sm={6} md={4} lg={4} xl={3.5} margin={1} >
                                                <div className='image-controller' >
                                                <div className='image-controller' >
                                                        {/* <ChangeCircleIcon onClick={() => ImageUpdate(img)} color='info' /> */}
                                                        <RemoveCircleIcon  onClick={() => ImageRemove(img)} color='error' onMouseOver={(e) => {
            e.currentTarget.style.cursor = 'pointer'; // Change cursor on hover
          }}  />
                                                    </div>
                            <Image key={index}
                            preview={false}
                            width={280}
                            height={180}
                            src={propertyImagesUrl+"/"+img.name}
                          />
                             </div>
                        </Grid>
                        ))
                     
                        }
                                </Grid>
                                <Typography variant='caption' color={'lightgray'} >
                                    Only max 10 images can be shown
                                </Typography>
                               
                            </div>
                            )}
                        </ImageUploading>

                        <div style={{ display: 'flex', gap: '20px' }}>
                        {/* {updateproperty?.images?.map((img,index) =>(
                            <Image key={index}
                            preview={false}
                            width={250}
                            height={150}
                            src={propertyImagesUrl+"/"+img.name}
                          />
                        ))} */}
                        </div>
                    </div>
                    
                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={3} xl={3}>
                        <TextField
                            label="Unit no"
                            value={unitno}
                            onChange={(e) => setUnitno(e.target.value)}
                            fullWidth
                            error={!!errors.unitno}
                            helperText={errors.unitno}
                        />
                    </Grid>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                                label="Project"
                                value={propertyName}
                                onChange={(e) => setPropertyName(e.target.value)}
                                fullWidth
                                error={!!errors.propertyName}
                                helperText={errors.propertyName}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={3} xl={3}>
                            <TextField
                                label="Total Area"
                                value={totalArea}
                                onChange={(e) => setTotalArea(e.target.value)}
                                fullWidth
                                error={!!errors.totalArea}
                                helperText={errors.totalArea}
                            />
                        </Grid>
                    </Grid>                    

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <FormControl fullWidth>
                                <InputLabel>No of Bath</InputLabel>
                                <Select
                                value={bathrooms}
                                onChange={(e) => setBathrooms(e.target.value)}
                                >
                                {Array.from({ length: 7 }, (_, i) => i + 1).map((num, index) => (
                                    <MenuItem key={index} value={num}>
                                    {num} Bathroom{num !== 1 ? 's' : ''}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <FormControl fullWidth>
                                <InputLabel>No of Bedrooms</InputLabel>
                                <Select
                                value={bedrooms}
                                onChange={(e) => setBedrooms(e.target.value)}
                                >
                                {Array.from({ length: 7 }, (_, i) => i + 1).map((num, index) => (
                                    <MenuItem key={index} value={num}>
                                    {num} Bedroom{num !== 1 ? 's' : ''}
                                    </MenuItem>
                                ))}
                                {/* <MenuItem value={'studio'}>Studio</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <FormControl fullWidth>
                                <InputLabel>Property Type</InputLabel>
                                <Select
                                value={propertyType}
                                onChange={(e) => {
                                    // console.log(e.target)
                                    setPropertyType(e.target.value)
                                }}
                                // defaultValue={PropertyDetails.type}
                                >
                                    
                                    {
                                    property_types.map((e, index)=>{
                                        return(
                                            <MenuItem key={index} value={e.id}>{e.name}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                                <FormHelperText className='text-danger'>{errors.propertyType}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Status</FormLabel>
                                <RadioGroup
                                row
                                value={listingType}
                                onChange={(e) => setListingType(e.target.value)}
                                >
                                <FormControlLabel
                                    value="Rent"
                                    control={<Radio color="primary" />}
                                    label="Rent"
                                />
                                <FormControlLabel
                                    value="Sale"
                                    control={<Radio color="primary" />}
                                    label="Sale"
                                />
                                 <FormControlLabel
                                    value="Off_plan"
                                    control={<Radio color="primary" />}
                                    label="Off Plan"
                                />
                                 <FormControlLabel
                                    value="Sold"
                                    control={<Radio color="primary" />}
                                    label="Sold"
                                />
                                    <FormControlLabel
                                    value="Ready"
                                    control={<Radio color="primary" />}
                                    label="Ready"
                                />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                           
                           <FormControl fullWidth>
                               <InputLabel>Developer</InputLabel>
                               <Select
                               value={developer}
                               onChange={(e) => setDeveloper(e.target.value)}
                               >
                               {
                                   developers.map((e, index)=>{
                                       return(
                                           <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
                                       )
                                   })
                               }
                               </Select>
                               <FormHelperText className='text-danger'>{errors.developer}</FormHelperText>
                           </FormControl>
                       </Grid>
                 

                        <Grid item xs={12} md={12} lg={4} xl={4}>
                            <CurrencyInput
                                style={{
                                    width: '100%', padding: '15px', boxSizing: 'border-box', border: '1px solid #ccc', borderRadius: '4px',
                                    fontSize: '16px', transition: 'border-color 0.3s'
                                }}
                                id="input-example"
                                name="input-name"
                                label="Price"
                                placeholder="Price"
                                //   defaultValue={0}
                                value={expectedPrice}
                                decimalsLimit={2}
                                onValueChange={(value, name, values) => setExpectedPrice(value)}
                                error={!!errors.expectedPrice}
                                helperText={errors.expectedPrice}
                            />
                            {/* <TextField
                                label="Price"
                                value={AmountSaperator(expectedPrice)}
                                onChange={(e) => setExpectedPrice(e.target.value)}
                                fullWidth
                                error={!!errors.expectedPrice}
                                helperText={errors.expectedPrice}
                            /> */}
                        </Grid>
                        <Grid item xs={12} md={12} lg={2} xl={2}>
                    <Button variant="contained" type="submit" style={{  backgroundColor: "black",width:'200px',height:'53px' }} onClick={showModal}>
                    Amenities  ( {selectedAmenities.length} )
                   </Button>
                    </Grid>
                    <Grid item xs={12} md={12} lg={2} xl={2}>
                    <Button variant="contained" type="submit" style={{  backgroundColor: "black",width:'200px',height:'53px' }} onClick={showDocumentModal}>
                    Documents
                   </Button>
                    </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                
                <Grid item xs={12} md={12} lg={12} xl={12}>
                <FormControl fullWidth>
<InputLabel>Owner</InputLabel>
<Select
    value={contact}
    onChange={(e) => setContact(e.target.value)}
>
    {
        contacts.map((e, index) => {
            return (
                <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
            )
        })
    }
</Select>
<FormHelperText className='text-danger'>{errors.contact}</FormHelperText>

</FormControl>
                </Grid>
            </Grid>
       

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                       
                       <FormControl fullWidth>
                           <InputLabel>location</InputLabel>
                           {/* <Autocomplete
      id="location_selected"
      options={locations}
      getOptionLabel={(option) => option.label}
      value={location}
      onChange={handleLocation}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Location"
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )}
    /> */}
                           <Select
                               value={location}
                               onChange={handleLocation}
                                id="location_selected"
                           >
                           {
                               locations.map((e, index)=>{
                                   return(
                                       <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
                                   )
                               })
                           }
                           </Select>
                           <FormHelperText className='text-danger'>{errors.location}</FormHelperText>
                       </FormControl>
                   </Grid>
                 
               
                        {/* <Grid item xs={12} md={12} lg={6} xl={6}>
                            <FormControl fullWidth>
                            
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={owner}
                                
                                    value={selectedUsers}
                                    onChange={ handleAutocompleteChange}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props} key={option.id} >
                                            <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            />
                                            <Avatar style={{marginRight:'10px'}} src={profileImageBaseUrl+"/"+option.profile_image} />
                                            {option.name}
                                        </li>
                                    )
                                    
                                }
                                  style={{ width: 400 }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Select Users" placeholder="" />
                                  )}
                                />


                            
                            </FormControl>
                        </Grid> */}
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: '2rem',marginBottom:'5px' }}>

<Grid item xs={12} md={12} lg={12} xl={12}>
    
    
           
            <div>
                <div className="map-wrapper">
                                  <div
                                    className="map-container"
                                    id="google-map"
                                  ></div>
                 </div>
                 <Helmet>
                                    <script
                                      type="text/javascript"
                                      charset="UTF-8"
                                      async={true}
                                      defer={true}
                                      src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCq6FzlBsSuT-S2zaRh2Rd-i4205DCu57s&callback=initMap&libraries=places"
                                    />
                                  </Helmet>
                                  </div>

                 </Grid>
                 </Grid>
         

                    <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" style={{ marginTop: '1rem', backgroundColor: "black" }} disabled={loading} >
                    {loading ? (
                <>
                  <BeatLoader color="white" size={5} />
                  Processing
                </>
              ) : (
                <>
                  <SaveIcon /> Update Property
                </>
              )}
                        
                    </Button>
                        
                </div>
            </div>
        // </Container>
    )
}

export default UpdatePropertyComp;
