import React, { useEffect, useState } from 'react';
import { 
    Button,
    Input,
    Typography,
    Avatar,
    Grid,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
// Local
import "./AddClientComp.css";
import { user } from '../LocalDB';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BaseImageUrl } from '../Components/BaseImageUrl';
import { BaseUrl } from '../Components/BaseUrl';
import { baseUrl } from '../Pages/Server/baseUrls';

const UpdateContactComp = ({id,xCLoseUpdateModal,updateClientData,countries
    // xImage, xName, xPhoneNumber, xEmail, xDob, xStatus, xAddress, xCountry, xDocument,
}) => {
    

  
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});

    const token = useSelector((state) => state.login.token);

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
            'Content-Type': 'multipart/form-data'
        },
    };

    useEffect(() =>{
            setName(updateClientData.name)
            setPhoneNumber(updateClientData.phone)
            setEmail(updateClientData.email)
            setCountry(updateClientData.country_id)
            setAddress(updateClientData.address)
   
    },[updateClientData])
  
    const handleNameChange = (event) => {
      setName(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
      };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
 

  
    const handleAddressChange = (event) => {
      setAddress(event.target.value);
    };
  
    const handleCountryChange = (event) => {
      setCountry(event.target.value);
    };

    
    const validate = () => {
        let input = inputs;
        let errors = {};
        let isValid = true;
        if (!name) {
          isValid = false;
          errors["name"] = "Name is required";
        }

        if (!phoneNumber) {
            isValid = false;
            errors["phoneNumber"] = "Phone Number is required";
          } 

          if (!country) {
            isValid = false;
            errors["country"] = "Country is required";
          }

          if (!email) {
            isValid = false;
            errors["email"] = "Email is required";
            
          } else if (!/\S+@\S+\.\S+/.test(email)) errors["email"] = 'Email is not valid';
       

        setErrors(errors)
        return isValid;
      };
  
    const handleProfileUpdate = () => {
     if(validate()){
        const formData = new FormData();
        formData.append('id',id)
        formData.append('name', name);
        formData.append('phone', phoneNumber);
        formData.append('email', email);
        formData.append('address', address);
        formData.append('country_id', country);
      
        
        axios.post(baseUrl+"/api/updatecontact", formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Important: set content type to multipart/form-data
                ...axiosConfig.headers // Include any other headers
            }
        })
        .then((res) => {
            console.log("res server", res.data);
        })
        .catch((error) => {
            console.error("Error:", error);
        });
        xCLoseUpdateModal()
    }else{
        return
    }
    };

    // Function to handle file input change


  

    return(
        <div style={{ width:"100%", display:'flex', alignItems:"center" , justifyContent:"center"}} >
            <div className='addClient-container' >
                {/* <h1> User Acount </h1> */}
                {/* <Typography variant="h5" style={{ fontFamily:'Montserrat'}} >{user.name}</Typography>
                <Typography variant="caption" style={{ fontFamily:'Montserrat'}} >{user.address}</Typography> */}

                

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={name}
                            onChange={handleNameChange}
                        />
                            <div className="text-danger">
                          {errors.name}
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                            <div className="text-danger">
                          {errors.email}
                        </div>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="Phone Number"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                            />
                                <div className="text-danger">
                          {errors.phoneNumber}
                        </div>
                    </Grid>

                </Grid> 

             

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Address"
                            value={address}
                            onChange={handleAddressChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                fullWidth
                                label="Select Country"
                                value={country}
                                onChange={handleCountryChange}
                            >
                                {countries.map((country,index)=>(
                                    <MenuItem key={index} value={country.id}>{country.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className="text-danger">
                          {errors.country}
                        </div>
                    </Grid>

                </Grid>
     

                <Grid item xs={12} style={{ marginTop: '2rem' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleProfileUpdate}
                    >
                        Update Contact
                    </Button>
                </Grid>
            </div>
        </div>
    )
}

export default UpdateContactComp;