import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import moment from 'moment';
import { AmountSaperator } from '../Utilities';

const rows = [
  // { date: "Feb 2023", amount: 66730 },
  // { date: "March 2023", amount: 42400 },
  // { date: "April 2023", amount: 86500 },
  // { date: "May 2023", amount: 57790 },
  // { date: "June 2023", amount: 76750 },
  // { date: "July 2023", amount: 57650 },
  { date: "Aug 2023", amount: 13400, status: 'Paid' },
  { date: "Sept 2023", amount: 87400, status: 'Paid' },
  { date: "Oct 2023", amount: 66730, status: 'Paid' },
  { date: "Nov 2023", amount: 10977, status: 'Paid' },
  { date: "Dec 2023", amount: 97076, status: 'Paid' },
  { date: "Jan 2024", amount: 34563, status: 'Paid' },
  { date: "Feb 2024", amount: 45345, status: 'Paid' },
  { date: "March 2024", amount: 45345, status: 'Paid' },
  { date: "April 2024", amount: 45345, status: 'Paid' },
  { date: "May 2024", amount: 45345, status: 'Upcoming' },
  { date: "June 2024", amount: 45345, status: 'Upcoming' },
  { date: "July 2024", amount: 45345, status: 'Upcoming' },
];

const TableComponent = ({xData}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

  return (
    // <Paper>
    //   <TableContainer>
    //     <Table>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>Date</TableCell>
    //           <TableCell>Amount</TableCell>
    //           <TableCell>Status</TableCell>
    //         </TableRow>
    //       </TableHead>
    //     <TableBody>
    //     {
    //     xData
    //         // .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort the rows array by date in descending order
    //         // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //         .map((row, index) => (
    //         <TableRow key={index}>
    //             <TableCell>{row.expect_date == null? "-" : row.expect_date }</TableCell>
    //             <TableCell>{AmountSaperator(row.amount)} AED</TableCell>
    //             <TableCell>{ row.expect_date >= currentDate? "Upcoming" : "Paid"}</TableCell>
    //         </TableRow>
    //         ))}
    //     </TableBody>
    //     </Table>
    //   </TableContainer>
    //   {/* <TablePagination
    //     rowsPerPageOptions={[5, 10, 25]}
    //     component="div"
    //     count={xData.length}
    //     rowsPerPage={rowsPerPage}
    //     page={page}
    //     onPageChange={handleChangePage}
    //     onRowsPerPageChange={handleChangeRowsPerPage}
    //   /> */}
    // </Paper>

    // id:3,
    // number_of_installment:'2nd Installment',
    // installment:"On Substantial completion of Residential Tower Enabling works",
    // Paymnet_perccentage:"10",
    // amount: 4914264.00

    <Paper>
      <TableContainer component={Paper} style={{ maxHeight: '400px', overflowY: 'auto' }} >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#222', color: '#fff' }} >
              <TableCell align='center' style={{ color: 'white' }} >No</TableCell>
              <TableCell align='center' style={{ color: 'white' }} >Installment</TableCell>
              <TableCell align='center' style={{ color: 'white' }} >Payment %</TableCell>
              <TableCell align='center' style={{ color: 'white' }} >Amount</TableCell>
              <TableCell align='center' style={{ color: 'white' }} >Date</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
        {
        xData.map((row, index) => (
            <TableRow key={index}>
                <TableCell align='center' >{row.number_of_installment }</TableCell>
                <TableCell align='center' >{row.installment_details}</TableCell>
                <TableCell align='center' >{ row.paymnet_perccentage}</TableCell>
                <TableCell align='center' >AED { AmountSaperator(row.amount)} </TableCell>
                <TableCell align='center' >{ row.date? moment(row.date).format('Do MMM YYYY') : ' -- ' }</TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableComponent;
