import React, { useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './kyc.css'
import axios from 'axios';
import { baseUrl } from '../../Server/baseUrls';
import { Snackbar } from '@material-ui/core';
import SignatureCanvas from 'react-signature-canvas';

import {
    Container,
    Typography,
    Button,
    TextField,
    // Select,
    MenuItem,
    Grid,
    Box,
    // Modal,
    Alert
  } from '@mui/material';
const KypForm = () => {
    const signaturePadRef = useRef(null);
    const [showToast, setShowToast] = React.useState(false);
    const [loading,setLoading] = useState(false)
    const [errors,setErrors] = useState({})
  const [formData, setFormData] = useState({
    typeOfListing: '',
    typeOfProperty: '',
    buildingName: '',
    unitNo: '',
    bedrooms: '',
    bathrooms: '',
    additionalRooms: '',
    builtUpArea: '',
    plotSize: '',
    furnished: 'no',
    view: '',
    balcony: 'no',
    exclusive: 'no',
    exclusiveTill: '',
    closeTo: '',
    parkingSpots: '',
    status: '',
    tenantTill: '',
    rentPrice: '',
    chiller: '',
    serviceCharge: '',
    amenities: '',
    developer: '',
    price: '',
    maxCheques: '',
    paymentPlan: '',
    completionStatus: '',
    agencyFee: '',
    keyPoints: '',
    titleDeed: null,
    passportCopy: null,
    signature: '',
    hqImages: []
  });

  const handleSignatureEnd = () => {
    if (signaturePadRef.current) {
        setFormData(prevData => ({
            ...prevData,
            signature: signaturePadRef.current.toDataURL()
        }));
    }
};

const handleClearSignature = () => {
    if (signaturePadRef.current) {
        signaturePadRef.current.clear();
        setFormData(prevData => ({
            ...prevData,
            signature: ''
        }));
    }
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files.length === 1 ? files[0] : files
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.listingType) newErrors.listingType = 'Listing type name is required';
    if (!formData.propertyType) newErrors.propertyType = 'Property type is required';
    if (!formData.bedrooms) newErrors.bedrooms = 'Number of bedrooms is required';
    if (!formData.buildingName) newErrors.buildingName = 'Building name / location is required';
   
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(validateForm()){
    
        
    try {
        const response = await axios.post(baseUrl+'/api/kyp', formData);

        if(response.data.success){

            setShowToast(true)
        //alert('Form submitted successfully');
        setFormData({   typeOfListing: '',
            typeOfProperty: '',
            buildingName: '',
            unitNo: '',
            bedrooms: '',
            bathrooms: '',
            additionalRooms: '',
            builtUpArea: '',
            plotSize: '',
            furnished: 'no',
            view: '',
            balcony: 'no',
            exclusive: 'no',
            exclusiveTill: '',
            closeTo: '',
            parkingSpots: '',
            status: '',
            tenantTill: '',
            rentPrice: '',
            chiller: '',
            serviceCharge: '',
            amenities: '',
            developer: '',
            price: '',
            maxCheques: '',
            paymentPlan: '',
            completionStatus: '',
            agencyFee: '',
            keyPoints: '',
            titleDeed: null,
            passportCopy: null,
            signature: '',})
            

            let hasFiles = false;
                const imagesFormData = new FormData();
                const images = formData.hqImages

              
              
                if(images && images.length>0){
                    hasFiles = true
                    for (let i = 0; i < images.length; i++) {
                      
                        imagesFormData.append(`images[]`, images[i]);  
                    }
                    
                }
            

               
                imagesFormData.append("kyp_id", response.data.kyp_id);
                if (hasFiles) {
                axios.post(baseUrl + "/api/kyp_uploads", imagesFormData)
                .then((res) =>{
                  setFormData({ typeOfListing: '',
                    typeOfProperty: '',
                    buildingName: '',
                    unitNo: '',
                    bedrooms: '',
                    bathrooms: '',
                    additionalRooms: '',
                    builtUpArea: '',
                    plotSize: '',
                    furnished: 'no',
                    view: '',
                    balcony: 'no',
                    exclusive: 'no',
                    exclusiveTill: '',
                    closeTo: '',
                    parkingSpots: '',
                    status: '',
                    tenantTill: '',
                    rentPrice: '',
                    chiller: '',
                    serviceCharge: '',
                    amenities: '',
                    developer: '',
                    price: '',
                    maxCheques: '',
                    paymentPlan: '',
                    completionStatus: '',
                    agencyFee: '',
                    keyPoints: '',
                    titleDeed: null,
                    passportCopy: null,
                    signature: '',hqImages:[]})
                    console.log("images upload",res.data);
                }).catch((err) =>{
                    console.log("err:",err);
                })
            }else{
                console.log("no images");

            }
        

        // console.log(response.data);
        }
    } catch (error) {
        setShowToast(false)
        console.error('Error submitting form', error);
        alert('Error submitting form');
    }
}
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowToast(false);
  };

  return (
    <div>  <div className="header">
    <img src="http://kestates.us.tempcloudsite.com/kyc/web_logo_white.jpg" alt="Company Logo" />
    <h1>KNOW YOUR PROPERTY | PROPERTY LISTINGS FORM
    </h1>
</div>  
<Snackbar
        open={showToast}
        onClose={handleCloseToast}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
        Form submitted successfully
        </Alert>
      </Snackbar>
    <form onSubmit={handleSubmit} className="containerf">
      <div className="form-row">
        <div className="form-group col-md-5">
          <label htmlFor="typeOfListing">Type of Listing</label>
          <select className="form-control" id="typeOfListing" name="typeOfListing" value={formData.typeOfListing} onChange={handleChange} required>
            <option value="">Select...</option>
            <option value="rent">Rent</option>
            <option value="sale">Sale</option>
          </select>
          <div className='text-danger'>
          {errors.listingType}
          </div>
        </div>

        <div className="form-group col-md-6">
          <label htmlFor="typeOfProperty">Type of Property</label>
          <select className="form-control" id="typeOfProperty" name="typeOfProperty" value={formData.typeOfProperty} onChange={handleChange} required>
            <option value="">Select...</option>
            <option value="Apartment">Apartment</option>
            <option value="Penthouse">Penthouse</option>
            <option value="Villa">Villa</option>
            <option value="Townhouse">Townhouse</option>
            <option value="Plot">Plot</option>
            <option value="Mansions">Mansions</option>
            <option value="Duplex">Duplex</option>
            <option value="Office">Office</option>
            <option value="Studio">Studio</option>
          </select>
          <div className='text-danger'>
          {errors.propertyType}
          </div>
        </div>
      </div>
      <div className="form-row">
      <div className="form-group col-md-5">
        <label htmlFor="buildingName">Building Name/Location</label>
        <input type="text" className="form-control" id="buildingName" name="buildingName" value={formData.buildingName} onChange={handleChange} required />
        <div className='text-danger'>
          {errors.buildingName}
          </div>
      </div>

      <div className="form-group col-md-6">
        <label htmlFor="unitNo">Unit No. / Villa No.</label>
        <input type="text" className="form-control" id="unitNo" name="unitNo" value={formData.unitNo} onChange={handleChange} />
      </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-5">
          <label htmlFor="bedrooms">Number of Bedrooms</label>
          <select className="form-control" id="bedrooms" name="bedrooms" value={formData.bedrooms} onChange={handleChange}>
            <option value="">select...</option>
            {[...Array(10)].map((_, i) => (
              <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
          </select>
          <div className='text-danger'>
          {errors.bedrooms}
          </div>
        </div>

        <div className="form-group col-md-6">
          <label htmlFor="bathrooms">Number of Bathrooms</label>
          <select className="form-control" id="bathrooms" name="bathrooms" value={formData.bathrooms} onChange={handleChange}>
          <option value="">select...</option>
            {[...Array(10)].map((_, i) => (
              <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-row">
      <div className="form-group col-md-5">
        <label htmlFor="additionalRooms">Additional rooms (Study / Maids / Laundry / Storage)</label>
        <input type="text" className="form-control" id="additionalRooms" name="additionalRooms" value={formData.additionalRooms} onChange={handleChange} />
      </div>

      <div className="form-group col-md-3">
        <label htmlFor="builtUpArea">Built-up Area</label>
        <input type="text" className="form-control" id="builtUpArea" name="builtUpArea" value={formData.builtUpArea} onChange={handleChange} />
      </div>

      <div className="form-group col-md-3">
        <label htmlFor="plotSize">Plot Size</label>
        <input type="text" className="form-control" id="plotSize" name="plotSize" value={formData.plotSize} onChange={handleChange} />
      </div>
      </div>
      <div className="form-row">
      <div className="form-group col-md-5">
        <label>Furnished or Unfurnished</label>
        <div className="form-check">
          <input className="form-check-input" type="radio" id="furnishedYes" name="furnished" value="yes" checked={formData.furnished === 'yes'} onChange={handleChange} />
          <label className="form-check-label" htmlFor="furnishedYes">Furnished</label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" id="furnishedNo" name="furnished" value="no" checked={formData.furnished === 'no'} onChange={handleChange} />
          <label className="form-check-label" htmlFor="furnishedNo">Unfurnished</label>
        </div>
      </div>

      <div className="form-group col-md-6">
        <label htmlFor="view">View</label>
        <input type="text" className="form-control" id="view" name="view" value={formData.view} onChange={handleChange} />
      </div>
      </div>

      <div className="form-group">
        <label>Balcony</label>
        <div className="form-check">
          <input className="form-check-input" type="radio" id="balconyYes" name="balcony" value="yes" checked={formData.balcony === 'yes'} onChange={handleChange} />
          <label className="form-check-label" htmlFor="balconyYes">Yes</label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" id="balconyNo" name="balcony" value="no" checked={formData.balcony === 'no'} onChange={handleChange} />
          <label className="form-check-label" htmlFor="balconyNo">No</label>
        </div>
      </div>

      <div className="form-group">
        <label>Exclusive or non-exclusive</label>
        <div className="form-check">
          <input className="form-check-input" type="radio" id="exclusiveYes" name="exclusive" value="yes" checked={formData.exclusive === 'yes'} onChange={handleChange} />
          <label className="form-check-label" htmlFor="exclusiveYes">Exclusive</label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="radio" id="exclusiveNo" name="exclusive" value="no" checked={formData.exclusive === 'no'} onChange={handleChange} />
          <label className="form-check-label" htmlFor="exclusiveNo">Non-exclusive</label>
        </div>
      </div>

      {formData.exclusive === 'yes' && (
        <div className="form-group">
          <label htmlFor="exclusiveTill">If exclusive, till when</label>
          <input type="text" className="form-control" id="exclusiveTill" name="exclusiveTill" value={formData.exclusiveTill} onChange={handleChange} />
        </div>
      )}

      <div className="form-group">
        <label htmlFor="closeTo">For Villas, is it close to a park, pool, mall, or school?</label>
        <input type="text" className="form-control" id="closeTo" name="closeTo" value={formData.closeTo} onChange={handleChange} />
      </div>
      <div className="form-row">
      <div className="form-group">
        <label htmlFor="parkingSpots">Allocated parking spots</label>
        <select className="form-control" id="parkingSpots" name="parkingSpots" value={formData.parkingSpots} onChange={handleChange}>
            <option value="">select...</option>
          {[...Array(10)].map((_, i) => (
            <option key={i + 1} value={i + 1}>{i + 1}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="status">Status (Vacant or Tenanted)</label>
        <select className="form-control" id="status" name="status" value={formData.status} onChange={handleChange}>
        <option value>select...</option>
          <option value="vacant">Vacant</option>
          <option value="tenanted">Tenanted</option>
        </select>
      </div>

      {formData.status === 'tenanted' && (
        <>
          <div className="form-group">
            <label htmlFor="tenantTill">If tenanted, till when</label>
            <input type="text" className="form-control" id="tenantTill" name="tenantTill" value={formData.tenantTill} onChange={handleChange} />
          </div>

          <div className="form-group">
            <label htmlFor="rentPrice">What's the rent price</label>
            <input type="text" className="form-control" id="rentPrice" name="rentPrice" value={formData.rentPrice} onChange={handleChange} />
          </div>
        </>
      )}

      <div className="form-group">
        <label htmlFor="chiller">Is it Chiller Free or separate</label>
        <select className="form-control" id="chiller" name="chiller" value={formData.chiller} onChange={handleChange}>
            <option value>select...</option>
          <option value="chiller-free">Chiller Free</option>
          <option value="separate">Separate</option>
        </select>
      </div>
      </div>
  <div className='form-row'>
      <div className="form-group col-md-3">
        <label htmlFor="serviceCharge">What is the service charge</label>
        <input type="text" className="form-control" id="serviceCharge" name="serviceCharge" value={formData.serviceCharge} onChange={handleChange} />
      </div>

      <div className="form-group col-md-3">
        <label htmlFor="amenities">What are the amenities</label>
        <input type="text" className="form-control" id="amenities" name="amenities" value={formData.amenities} onChange={handleChange} />
      </div>

      <div className="form-group col-md-5">
        <label htmlFor="developer">Developer</label>
        <input type="text" className="form-control" id="developer" name="developer" value={formData.developer} onChange={handleChange} />
      </div>
      </div>
      <div className='form-row'>
      <div className="form-group col-md-5">
        <label htmlFor="price">Selling/Renting Price</label>
        <input type="text" className="form-control" id="price" name="price" value={formData.price} onChange={handleChange} />
      </div>

      <div className="form-group col-md-6">
        <label htmlFor="maxCheques">Maximum number of cheques</label>
        <select className="form-control" id="maxCheques" name="maxCheques" value={formData.maxCheques} onChange={handleChange}>
          {[...Array(10)].map((_, i) => (
            <option key={i + 1} value={i + 1}>{i + 1}</option>
          ))}
        </select>
      </div>
</div>
<div className='form-row'>
      <div className="form-group col-md-4">
        <label htmlFor="paymentPlan">If it is for sale, does it have a payment plan</label>
        <input type="text" className="form-control" id="paymentPlan" name="paymentPlan" value={formData.paymentPlan} onChange={handleChange} />
      </div>

      <div className="form-group col-md-5">
        <label htmlFor="completionStatus">If for sale, is it completed, off-plan, or under construction</label>
        <input type="text" className="form-control" id="completionStatus" name="completionStatus" value={formData.completionStatus} onChange={handleChange} />
      </div>

      <div className="form-group col-md-2">
        <label htmlFor="agencyFee">Seller/Landlord’s agency fee</label>
        <input type="text" className="form-control" id="agencyFee" name="agencyFee" value={formData.agencyFee} onChange={handleChange} />
      </div>
      </div>
      <div className="form-group">
        <label htmlFor="keyPoints">3 key points of the property</label>
        <textarea className="form-control" id="keyPoints" name="keyPoints" rows="3" value={formData.keyPoints} onChange={handleChange}></textarea>
      </div>

      <div className="form-group">
        <label htmlFor="titleDeed">Copy of Title Deed or Oqood</label>
        <input type="file" className="form-control-file" id="titleDeed" name="titleDeed" onChange={handleFileChange} />
      </div>

      <div className="form-group">
        <label htmlFor="passportCopy">Copy of Owners Passport or Emirates ID</label>
        <input type="file" className="form-control-file" id="passportCopy" name="passportCopy" onChange={handleFileChange} />
      </div>

     
        <div className='form-row'>
      <div className="form-group col-md-4">
                        <label>Signature:</label>
                        <SignatureCanvas
                        // value={formData.signature}
                                ref={signaturePadRef}
                                penColor='black'
                                canvasProps={{ width: 500, height: 200, className: 'signature-canvas' }}
                                onEnd={handleSignatureEnd} 
                            />
                            {/* <button type="button" onClick={handleSignature}>Save Signature</button> */}
                            
                            <button type="button" onClick={handleClearSignature}>Clear Signature</button>

                    </div>
                    <div className='form-group col-md-4'>
                    </div>
                     </div>
                    
      <div className="form-group">
        <label htmlFor="hqImages">HQ Images (minimum of 10, maximum of 30)</label>
        <input type="file" className="form-control-file" id="hqImages" name="hqImages" multiple onChange={handleFileChange} />
      </div>
 <div style={{margin:'10px'}}>
      <button type="submit" className="btn btn-primary">Submit</button>
      </div>
    </form>
    </div>
  );
};

export default KypForm;
