import React, { useEffect, useState } from 'react';
import { Button, Typography, Grid, Container } from '@mui/material';
import "./index.css";
import { useNavigate } from 'react-router-dom';
// Local
import UserDetails from '../../Components/usersDetailComp';
import PropetyComp from '../../Components/PropertyComp';
import PropertyPriceSlider from '../../Components/PropertyPriceSlider';
import HomePageComp from '../../Components/HomePageComp';
import PropertyTableComp from '../../Components/PropertyTableComp';
import HomePageComp_2 from '../../Components/HomePageComp_2';

const generateData = () => {
    const data=[];
    for (let i = 1; i <= 11; i++) {
      data.push({
        id: i,
        xSlideImagesArr:[
            {
                url: 'https://kestates.ae/wp-content/uploads/2024/01/SharedScreenshotfff.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://kestates.ae/wp-content/uploads/2024/01/Sky_Residences_Interior_Image_04.png',
                caption: 'Slide 3'
            },
            {
                url: 'https://kestates.ae/wp-content/uploads/2024/01/Sky_Residences_Interior_Image_01.png',
                caption: 'Slide 3'
            },
            {
                url: 'https://kestates.ae/wp-content/uploads/2024/01/Sky-Residences-Expo-.jpg',
                caption: 'Slide 3'
            },
        
        
            {
                url: 'https://kestates.ae/wp-content/uploads/2024/01/main.jpg',
                caption: 'Slide 3'
            },
        ],
        xPropertyName: `Property ${i}`,
        xPrice: `${i * 1000000}`,
        type: i % 2 === 0 ? 'villa' : 'apartment',
        location: `City ${String.fromCharCode(65 + (i % 5))}`,
        developer: `Developer ${i % 3 + 1}`,
        status: i % 2 === 0 ? 'sale' : 'rent',
        xArea: `${i * 100} sqft`,
        xDescription:'Sky Residences offers an urban living experience that emphasizes health, wellness, community engagement, and environmental consciousness. Experience a lifestyle that seamlessly blends smart, eco-friendly design with luxurious amenities, all while ensuring convenient access to the city’s highlights. Sky Residences epitomizes the belief that true well-being stems from a harmonious balance of living well and being well. This principle is embodied in its community-focused design, which includes lush green spaces, jogging paths, and modern wellness facilities, all aimed at nurturing a joyful and healthy lifestyle. The commitment to communal living at Sky Residences is showcased through its three levels dedicated to social activities. These include beautifully landscaped podium gardens and inviting sky terraces, offering ideal spaces for quality time with family and friends. Residents can relish the convenience and enjoyment of a lively urban community, right outside their doors.',
        xNoOfBath:"2",
        xNoOfBeds:"2",
        xRefNumber:"1101",
        xTrending: true,
        listed_with_Kestates:  i % 3 === 0 ? true : false,
        //////////

        // xArea:"2957", 
        // xDescription:"Sky Residences offers an urban living experience that emphasizes health, wellness, community engagement, and environmental consciousness. Experience a lifestyle that seamlessly blends smart, eco-friendly design with luxurious amenities, all while ensuring convenient access to the city’s highlights. Sky Residences epitomizes the belief that true well-being stems from a harmonious balance of living well and being well. This principle is embodied in its community-focused design, which includes lush green spaces, jogging paths, and modern wellness facilities, all aimed at nurturing a joyful and healthy lifestyle. The commitment to communal living at Sky Residences is showcased through its three levels dedicated to social activities. These include beautifully landscaped podium gardens and inviting sky terraces, offering ideal spaces for quality time with family and friends. Residents can relish the convenience and enjoyment of a lively urban community, right outside their doors.",
        // xNoOfBath:"2",
        // xNoOfBeds:"3",
       // xPropertyName:"Sky Residences, Expo City", 
        // xRefNumber:"1101",
        // xTrending: true,
        // xPrice: '4,427,000',
        // listed_with_Kestates: true,
      });
    }
    return data;
  };



const Home = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(generateData());

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
   
    return(
        <>
            
            <div>
                <PropertyPriceSlider />
            </div>
            {/* <HomePageComp /> */}
            <HomePageComp_2 />
            <Container maxWidth={'xl'} >
                <div className='home_container'>
                    {/* <div className='first_container'>
                        <UserDetails />
                    </div>
                    <Grid container spacing={2} alignItems="center" justifyContent="space-between" width={"100%"} marginTop={'7%'} > 
                        <Grid item xs={12} md={6}> 
                            <Typography variant="h4" style={{ fontFamily: 'Montserrat' }}>
                                My Property
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display:'flex', alignItems:"center", justifyContent:"flex-end" }} > 
                            <Button
                                variant="contained"
                                style={{ margin: '1rem', backgroundColor: 'black'}}
                                onClick={() => { navigate("/Listings_Listed_With_K_Estaes")}}
                            >
                                List your Property with K-Estates
                            </Button>
                        </Grid>
                    </Grid>
                    <PropetyComp /> */}
                     <Typography variant="h3" style={{ fontSize:'1.75rem', fontFamily: 'Montserrat', marginTop:'2rem', marginBottom:'2rem' }}>
                        My Properties
                    </Typography>
                    <PropertyTableComp 
                        // xData={data} 
                        // xOnClick={() => { navigate("/ListingsDetails", {state: {propertyDetails: data}})}}
                    />
                </div>
            </Container>
        </>
    )
}

export default Home;
